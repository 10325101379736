import HTTPRequest from '../index';

export type ValueResolve = {
  solution?: string;
};

const apiPath = '/admin/report-users';
class Report extends HTTPRequest {
  getList(params: Payload.Report) {
    return this.get<Response.ReportResponse>(apiPath, { params });
  }

  getDetail(id: string) {
    return this.get<Response.ReportDetailResponse>(`${apiPath}/${id}`);
  }

  updateSolution(id: string, data?: ValueResolve) {
    return this.put(`${apiPath}/${id}`, data);
  }
}
export default new Report();
