import React from 'react';
import { Card, Table as AntTable } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import useTable from 'src/hook/useTable';
import column from './column';
import { getTotalByAmountType } from 'src/utils/Helpers';

type Props = {
  loading: boolean;
  entities: Response.MemberPackageEntity[];
};

const Table: React.FC<Props> = ({ entities, loading }) => {
  const { t } = useTranslation();
  const { pagination, onChange } = useTable<Response.MemberPackageEntity>();

  return (
    <>
      <Card>
        <div>
          <AntTable
            rowKey="id"
            loading={loading}
            columns={column(t)}
            dataSource={entities.length ? entities : []}
            pagination={pagination}
            onChange={onChange}
            scroll={{ x: 'max-content' }}
          />
        </div>
      </Card>
      <Total>
        <PaymentAmount>
          <TotalTitle>{t('total.payment_amount')}:</TotalTitle>
          <Content>{getTotalByAmountType(entities)}</Content>
        </PaymentAmount>
      </Total>
    </>
  );
};

const Total = styled.div`
  margin-top: 20px;
`;
const PaymentAmount = styled.div`
  display: flex;
`;

const Content = styled.div`
  color: #e96bc5;
  margin-left: 30px;
  font-weight: 700;
`;

const TotalTitle = styled.div``;

export default Table;
