import React from 'react';

import { formatDate, renderMemberPackage } from 'src/utils/Helpers';
import PackageStatus from 'src/components/badge/PackageStatus';

const column: App.ColumnsFnc<Response.MemberPackageEntity> = (t, sortInfo) => [
  {
    title: t('column.actionId'),
    dataIndex: 'id',
    key: 'actionId',
  },
  {
    title: t('column.name'),
    key: 'name',
    render: (v, row) => {
      return (
        <div>
          <img src={row.avatar} width={20} style={{ marginRight: '5px' }} />
          <span>{row.fullname || '-'}</span>
        </div>
      );
    },
  },

  {
    title: t('column.email'),
    dataIndex: 'email',
    key: 'email',
  },

  {
    title: t('column.member_package'),
    key: 'package',
    render: (v, row) => {
      return renderMemberPackage(row.member_package);
    },
  },
  {
    title: t('column.duration'),
    dataIndex: 'duration',
    key: 'duration',
  },
  {
    title: t('column.payment_amount'),
    dataIndex: 'payment_amount',
    key: 'paymentAmount',
  },
  {
    title: t('column.payment_date'),
    key: 'paymentDate',
    render: (v, row) => {
      return formatDate(row.payment_date);
    },
  },

  {
    title: t('column.package_status'),
    key: 'packageStatus',
    render: (v, row) => {
      return <PackageStatus status={row.status} />;
    },
  },
];

export default column;
