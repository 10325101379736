import HTTPRequest from '../index';

const apiPathCouple = '/admin/users/match';
const apiPathSwipe = '/admin/users/reaction';

class Match extends HTTPRequest {
  getSwipe(params: Payload.Match_swipe) {
    return this.get<Response.MatchSwipesResponse>(apiPathSwipe, { params });
  }

  getCouple(params: Payload.Match_couple) {
    return this.get<Response.MatchCoupleResponse>(apiPathCouple, { params });
  }
}

export default new Match();
