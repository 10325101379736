import React from 'react';

import { IRouter } from 'src/interfaces/route';
import { PATH } from 'src/pages/member';
import Layout from 'src/components/layout';
import loadable from 'src/utils/Loadable';
import { t } from 'src/libs/i18n';

const MemberRoute: IRouter[] = [
  {
    element: <Layout />,
    children: [
      {
        path: PATH,
        element: loadable(() => import('src/pages/member')),
        title: t('title.member_package'),
      },
    ],
  },
];

export default MemberRoute;
