import React from 'react';
import { Tabs, TabsProps } from 'antd';
import qs from 'query-string';

import useQueryUrl from 'src/hook/useQueryUrl';

type Props = {
  tabKey?: string;
  search?: (tab: string) => object | false;
} & TabsProps;

const CustomTabs: React.FC<Props> = (props) => {
  const { children, tabKey, search, ...rest } = props;
  const { query, setSearchQuery } = useQueryUrl();

  const onChange = (tab: string) => {
    const isClear = search?.(tab) === false;

    setSearchQuery(
      qs.stringify({
        ...(isClear ? {} : query),
        ...search?.(tab),
        [tabKey || 'tab']: tab,
      }),
    );
  };

  return (
    <Tabs {...rest} onChange={onChange} activeKey={query[tabKey || 'tab']}>
      {children}
    </Tabs>
  );
};

export default CustomTabs;
