import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

import matchCouple from 'src/store/domain/match/matchCouple';
import appAction from 'src/store/ui/app';
import matchRequest from 'src/api/requests/match';

interface FetchResource extends SearchParams.Match_Couple {}

const getParams = (args: FetchResource) => {
  const {
    limit,
    page,
    search_type,
    period_search,
    search_text,
    from_date,
    to_date,
  } = args;

  const params: Payload.Match_couple = {
    limit,
    page,
    period_search,
    type: search_type,
    keyword: search_text,
    created_at_from: from_date,
    created_at_to: to_date,
  };

  return params;
};

export const fetchResource = createAsyncThunk<void, FetchResource>(
  'get_match_couple',
  async (args, { dispatch }) => {
    try {
      dispatch(matchCouple.updateLoading(true));
      const params = getParams(args);
      const res = await matchRequest.getCouple(_.omitBy(params, _.isEmpty));
      const { data, meta } = res.data;

      if (data) {
        dispatch(matchCouple.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appAction.updatePagination({
            total: meta.total,
            current: meta.current_page,
          }),
        );
      }

      dispatch(matchCouple.updateLoading(false));
    } catch (err) {
      dispatch(matchCouple.updateLoading(false));
    }
  },
);
