import { MATCH_ACTION } from 'src/enum/match';
export * from './common';

export const match_action: App.OptionsFnc = (t) => [
  {
    label: t('option.all'),
    value: MATCH_ACTION.ALL,
  },
  {
    label: t('label.like'),
    value: MATCH_ACTION.LIKE,
  },
  {
    label: t('label.nope'),
    value: MATCH_ACTION.NOPE,
  },
  {
    label: t('label.back'),
    value: MATCH_ACTION.BACK,
  },
  {
    label: t('label.super_like'),
    value: MATCH_ACTION.SUPER_LIKE,
  },
];
