import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'antd';
import { CaretLeftOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import RowDetail from 'src/components/common/RowDetail';

type Props = {
  loading: boolean;
  entities: Response.UserDetailEntity | null;
};

const Interaction: React.FC<Props> = ({ entities, loading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Card loading={loading}>
        <RowDetail
          layout={{
            titleCol: { span: 7 },
          }}
          title={t('label.number_of_like')}
          value={entities?.reactions.total_likes}
        />
        <RowDetail
          layout={{
            titleCol: { span: 7 },
          }}
          title={t('label.number_of_nope')}
          value={entities?.reactions.total_nopes}
        />
        <RowDetail
          layout={{
            titleCol: { span: 7 },
          }}
          title={t('label.number_of_match')}
          value={entities?.reactions.total_matches}
        />
        <RowDetail
          layout={{
            titleCol: { span: 7 },
          }}
          title={t('label.number_of_back')}
          value={entities?.reactions.total_backs}
        />
        <RowDetail
          layout={{
            titleCol: { span: 7 },
          }}
          title={t('label.number_of_supper_like')}
          value={entities?.reactions.total_super_likes}
        />
        <RowDetail
          layout={{
            titleCol: { span: 7 },
          }}
          title={t('label.member_package')}
          value={entities?.package.attributes.title}
        />
      </Card>
      <Button icon={<CaretLeftOutlined />} onClick={() => navigate(-1)}>
        {t('button.back')}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ant-card {
    margin-bottom: 10px;
    padding-bottom: 200px !important;
  }
  .ant-form-item-label {
    text-align: left;
  }
  .ant-radio-button-wrapper {
    margin-right: 8px;
  }
`;

export default Interaction;
