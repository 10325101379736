import { useForm } from 'react-hook-form';

import { SETTING_OPTION } from 'src/enum/memberPackage';
import { getOptionConfigSetting } from 'src/utils/Helpers';
import { PAYMENT_METHODS, PAYMENT_TYPE } from 'src/enum/settingMemberPackage';

export type FormValues = {
  options: string;
};

type Values = Response.SettingEntity[] | null;

const useFormSetting = (entities: Values) => {
  const defaultValues: FormValues = {
    options: SETTING_OPTION.USDT,
  };

  const form = useForm<FormValues>({
    defaultValues,
    mode: 'all',
  });

  const getCreditPrice = getOptionConfigSetting({
    value: entities,
    payment: PAYMENT_METHODS.CREDIT,
    type: PAYMENT_TYPE.USD,
  });

  const getIAPPrice = getOptionConfigSetting({
    value: entities,
    payment: PAYMENT_METHODS.INAPP,
    type: PAYMENT_TYPE.USD,
  });

  const getBUSDPrice = getOptionConfigSetting({
    value: entities,
    payment: PAYMENT_METHODS.CRYPTO,
    type: PAYMENT_TYPE.BUSD,
  });
  const getBNBPrice = getOptionConfigSetting({
    value: entities,
    payment: PAYMENT_METHODS.CRYPTO,
    type: PAYMENT_TYPE.BNB,
  });

  const getUSDTPrice = getOptionConfigSetting({
    value: entities,
    payment: PAYMENT_METHODS.CRYPTO,
    type: PAYMENT_TYPE.USDT,
  });

  return {
    form,
    creditPrice: getCreditPrice,
    USDTPrice: getUSDTPrice,
    BUSDPrice: getBUSDPrice,
    IAPPrice: getIAPPrice,
    BNBPrice: getBNBPrice,
  };
};

export default useFormSetting;
