import React from 'react';

type Props = {
  creativities: string[];
  sports: string[];
  pets: string[];
  travelings: string[];
};

const RenderInterest: React.FC<Props> = ({
  creativities,
  sports,
  pets,
  travelings,
}) => {
  return (
    <>
      {!!creativities.length && (
        <li style={{ listStyleType: 'none' }}>
          creativities: {creativities.join(', ')}
        </li>
      )}
      {!!sports.length && (
        <li style={{ listStyleType: 'none' }}>sports: {sports.join(', ')}</li>
      )}
      {!!pets.length && (
        <li style={{ listStyleType: 'none' }}>pets: {pets.join(', ')}</li>
      )}
      {!!travelings.length && (
        <li style={{ listStyleType: 'none' }}>
          travelings: {travelings.join(', ')}
        </li>
      )}
    </>
  );
};
export default RenderInterest;
