import HTTPRequest from '../index';

const apiPath = '/admin/users/member-package';

class MemberPackage extends HTTPRequest {
  getList(params: Payload.Users) {
    return this.get<Response.MemberPackageListResponse>(apiPath, { params });
  }
}

export default new MemberPackage();
