export enum MEMBER_PACKAGE_SEARCH_TYPE {
  EMAIL = '2',
  USER_NAME = '1',
}
export enum MEMBER_DURATION {
  ALL = '',
  ONE_MONTH = 1,
  THREE_MONTHS = 3,
  SIX_MONTHS = 6,
}

export enum MEMBER_DURATION_STATUS {
  ALL = '',
  WAITTING = 1,
  SUCCESS = 2,
  FAILURE = 3,
  CANCELED = 4,
  REFUNDED = 5,
}
export enum MEMBER_PACKAGE {
  ALL = '',
  PLUS = 2,
  GOLD = 3,
  PLATINUM = 4,
  VIP = 5,
}

export enum AMOUNT_TYPE {
  ALL = '',
  BNB = 1,
  USDT = 2,
  USD = 3,
  LOLO = 4,
  BUSD = 5,
}

export enum SETTING_OPTION {
  USDT = '1',
  BUSD = '2',
  PAYMENT_IN_APP = '3',
  CREDIT_CARD = '4',
  BNB = '5',
}
export enum CONFIG_OPTION {
  USDT = '1',
  PAYMENT_IN_APP = '2',
  BNB = '3',
  LOLO = '4',
}
