import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { login } from 'src/useCase/auth';
import { useAppDispatch } from 'src/store';

export type FormValues = {
  email?: string;
  password?: string;
};

export const useLogin = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const defaultValues: FormValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    await dispatch(login({ credentials: data, meta: form, t }));
  };

  const form = useForm<FormValues>({
    defaultValues,
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  return {
    form,
    onSubmit,
  };
};
