import React from 'react';
import { Outlet } from 'react-router-dom';

import { IRouter } from 'src/interfaces/route';
import { ForbiddenPage, NotFoundPage, ServerErrorPage } from 'src/pages/error';
import { HTTP_CODE } from 'src/constants/app';

const DashboardRoute: IRouter[] = [
  {
    element: <Outlet />,
    children: [
      {
        path: `/${HTTP_CODE.NOT_FOUND}`,
        element: <NotFoundPage />,
      },
      {
        path: `*`,
        element: <NotFoundPage />,
      },
      {
        path: `/${HTTP_CODE.FORBIDDEN}`,
        element: <ForbiddenPage />,
      },

      {
        path: `/${HTTP_CODE.INTERNAL_SERVER_ERROR}`,
        element: <ServerErrorPage />,
      },
    ],
  },
];

export default DashboardRoute;
