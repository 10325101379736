import React from 'react';

import { IRouter } from 'src/interfaces/route';
import { PATH } from 'src/pages/user';
import { PATH as PATHDETAIL } from 'src/pages/userDetail';
import Layout from 'src/components/layout';
import loadable from 'src/utils/Loadable';
import { t } from 'src/libs/i18n';

const UserRoute: IRouter[] = [
  {
    element: <Layout />,
    children: [
      {
        path: PATH,
        element: loadable(() => import('src/pages/user')),
        title: t('title.user_management'),
      },
      {
        path: PATHDETAIL,
        element: loadable(() => import('src/pages/userDetail')),
      },
    ],
  },
];

export default UserRoute;
