import React, { useEffect } from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

type Props = {
  handleCloseNotification: () => void;
  isNotice: boolean;
  type?: 'success' | 'error' | 'warning' | 'info';
  description?: string;
  message?: string;
};

const Notification = (props: Props) => {
  const {
    handleCloseNotification,
    type = 'open',
    description,
    message,
    isNotice,
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    if (isNotice) {
      notification[type]({
        message: t(message as any),
        description,
      });
      handleCloseNotification();
    }
  }, [isNotice]);

  return <React.Fragment></React.Fragment>;
};

export default Notification;
