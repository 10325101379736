import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { TabsWrapper } from 'src/components/common';
import ReportDetailContainer from 'src/containers/ReportDetail';
import { useAppDispatch } from 'src/store';
import { fetchResource } from 'src/useCase/reportDetail';

const Page: App.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchResource(id));
  }, []);

  return (
    <TabsWrapper>
      <ReportDetailContainer />
    </TabsWrapper>
  );
};

export const PATH = '/report/:id' as const;
export default Page;
