import React from 'react';
import { Image } from 'antd';
import styled from 'styled-components';

import imgDefault from 'src/assets/defaultUpload.png';

type Props = {
  list: any[];
  srcKey?: string;
  width?: number;
  height?: number;
};

const ImagePreview: React.FC<Props> = ({
  list,
  width = 150,
  height = 150,
  srcKey = 'url',
}) => {
  return (
    <Wrapper>
      <Image.PreviewGroup>
        {list.length ? (
          list.map((file, id) => {
            return (
              <Image
                key={id}
                src={file[srcKey]}
                width={width}
                height={height}
                fallback={imgDefault}
              />
            );
          })
        ) : (
          <Image src={imgDefault} width={width} height={height} />
        )}
      </Image.PreviewGroup>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ant-image {
    margin-right: 8px;
    margin-top: 5px;
    img {
      object-fit: cover;
    }
  }
`;

export default ImagePreview;
