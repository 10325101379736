import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, BadgeProps } from 'antd';

import { MEMBER_DURATION_STATUS } from 'src/enum/memberPackage';

type Props = {
  status: MEMBER_DURATION_STATUS;
};

const PackageStatus: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation();

  const getProps = (): BadgeProps => {
    switch (status) {
      case MEMBER_DURATION_STATUS.WAITTING:
        return { status: 'processing', text: t('option.watting') };
      case MEMBER_DURATION_STATUS.SUCCESS:
        return { status: 'success', text: t('option.success') };
      case MEMBER_DURATION_STATUS.FAILURE:
        return { status: 'error', text: t('option.failure') };
      case MEMBER_DURATION_STATUS.CANCELED:
        return { status: 'error', text: t('option.canceled') };
      case MEMBER_DURATION_STATUS.REFUNDED:
        return { status: 'warning', text: t('option.refunded') };
      default:
        return { status: 'default', text: t('option.all') };
    }
  };

  return <Badge {...getProps()} />;
};

export default PackageStatus;
