import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

import {
  MEMBER_PACKAGE_SEARCH_TYPE,
  MEMBER_DURATION_STATUS,
  MEMBER_DURATION,
  MEMBER_PACKAGE,
} from 'src/enum/memberPackage';
import useQueryUrl from 'src/hook/useQueryUrl';
import history from 'src/libs/history';

export type FormValues = {
  search_type: string;
  search_text: string;
  status: MEMBER_DURATION_STATUS;
  member_package: MEMBER_PACKAGE;
  duration: MEMBER_DURATION;
  period_search: string;
  quick_period_search?: string;
  from_date?: string;
  to_date?: string;
  page: string;
  limit: string;
};

const useSearch = () => {
  const { query, setSearchQuery } = useQueryUrl();
  const location = useLocation();
  const { t } = useTranslation();

  const defaultValues: FormValues = {
    search_type: MEMBER_PACKAGE_SEARCH_TYPE.EMAIL,
    search_text: '',
    status: MEMBER_DURATION_STATUS.ALL,
    member_package: MEMBER_PACKAGE.ALL,
    duration: MEMBER_DURATION.ALL,
    period_search: '',
    quick_period_search: '',
    from_date: '',
    to_date: '',
    page: '1',
    limit: '10',
  };

  const validationSchema = Yup.object().shape({
    from_date: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),
    to_date: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('from_date', (from_date, schema) => {
        if (!from_date) {
          return;
        }
        return schema.min(from_date, t('validated.from_to_date'));
      }),
  });

  const handleSearch = () => {
    form.handleSubmit((data) => {
      history.push({
        search: qs.stringify({
          ...data,
          page: 1,
          from_date: form.getValues()?.from_date,
          to_date: form.getValues()?.to_date,
        }),
      });
    })();
  };

  const resetForm = async () => {
    await form.reset({ ...defaultValues });
    setSearchQuery('');
  };

  const form = useForm<FormValues>({
    defaultValues: location.search === '' ? defaultValues : query,
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  return {
    form,
    resetForm,
    handleSearch,
  };
};

export default useSearch;
