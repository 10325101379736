import { Modal, ModalFuncProps } from 'antd';
import { t } from 'src/libs/i18n';

type Props = Omit<ModalFuncProps, 'type'>;

const modalConfirm = (props: Props) => {
  const {
    title,
    okText,
    cancelText,
    content,
    okButtonProps,
    cancelButtonProps,
    ...rest
  } = props;

  return Modal.confirm({
    ...rest,
    title,
    content,
    maskClosable: true,
    // style: {
    //   display: 'flex',
    //   justifyContent: 'space-between',
    //   float: 'inherit',
    // },
    okText: okText || t('button.yes'),
    cancelText: cancelText || t('button.no'),
    icon: null,
    okButtonProps: {
      style: { float: 'inherit' },
      ...okButtonProps,
    },
    cancelButtonProps: {
      ...cancelButtonProps,
    },
    centered: true,
    autoFocusButton: null,
  });
};

export default modalConfirm;
