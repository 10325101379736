import { Badge, BadgeProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  status?: boolean;
};

const ReportStatus: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation();

  const getProps = (): BadgeProps => {
    if (!status) {
      return { status: 'success', text: t('option.new') };
    } else {
      return { status: 'error', text: t('option.completed') };
    }
  };
  return <Badge {...getProps()} />;
};
export default ReportStatus;
