// import React from 'react';

import { REPORT_REASON } from 'src/enum/report';

export * from './common';

export const report_reason: App.OptionsFnc = (t) => [
  {
    label: t('option.all'),
    value: REPORT_REASON.ALL,
  },
  {
    label: t('option.fake_profile_or_spam'),
    value: REPORT_REASON.FAKE_PROFILE_OR_SPAM,
  },
  {
    label: t('option.imappropriate_message'),
    value: REPORT_REASON.IMAPPROPRIATE_MESSAGE,
  },
  {
    label: t('option.imappropriate_profile_photo'),
    value: REPORT_REASON.IMAPPROPRIATE_PROFILE_PHOTO,
  },
  {
    label: t('option.offline_behavior'),
    value: REPORT_REASON.OFFLINE_BEHAVIOR,
  },
];
