import { useForm } from 'react-hook-form';

export type FormValues = {
  solution: string;
};

const useResolve = () => {
  const defaultValues: FormValues = {
    solution: '',
  };

  const form = useForm<FormValues>({
    defaultValues,
    shouldFocusError: true,
    mode: 'all',
  });

  return { form };
};

export default useResolve;
