import React from 'react';
import styled from 'styled-components';
import { Button, Row, Divider } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

import useFormSetting, { FormValues } from './useForm';
import modalConfirm from 'src/utils/modalConfirm';
import { t } from 'src/libs/i18n';
import { InputNumberField } from 'src/components/form';
import { useAppDispatch } from 'src/store';
import { SETTING_DURATION, TITLE_MP } from 'src/enum/settingMemberPackage';
import { updateSettingMPPrices } from 'src/useCase/settingMemberPackage';
import { getID } from 'src/utils/Helpers';

type Props = {
  price: Array<Response.SettingExtraPrice[]>;
};
const PaymentInApp: React.FC<Props> = ({ price }) => {
  const { form } = useFormSetting(price);
  const { control, getValues, formState } = form;
  const dispatch = useAppDispatch();

  const handleConfirm = () => {
    modalConfirm({
      title: t('message.setting_confirm'),
      okText: t('button.confirm'),
      style: { textAlign: 'center' },
      onOk: async () => {
        await dispatch(
          updateSettingMPPrices({
            prices: [
              {
                id: getID(price, TITLE_MP.plus, SETTING_DURATION.A_WEEK),
                amount: getValues().plus_aWeek_payment_in_app,
              },
              {
                id: getID(price, TITLE_MP.plus, SETTING_DURATION.ONE_MONTH),
                amount: getValues().plus_oneMonth_payment_in_app,
              },
              {
                id: getID(price, TITLE_MP.plus, SETTING_DURATION.THREE_MONTHS),
                amount: getValues().plus_threeMonth_payment_in_app,
              },
              {
                id: getID(price, TITLE_MP.plus, SETTING_DURATION.SIX_MONTHS),
                amount: getValues().plus_sixMonth_payment_in_app,
              },
              {
                id: getID(price, TITLE_MP.gold, SETTING_DURATION.A_WEEK),
                amount: getValues().gold_aWeek_payment_in_app,
              },
              {
                id: getID(price, TITLE_MP.gold, SETTING_DURATION.ONE_MONTH),
                amount: getValues().gold_oneMonth_payment_in_app,
              },
              {
                id: getID(price, TITLE_MP.gold, SETTING_DURATION.THREE_MONTHS),
                amount: getValues().gold_threeMonth_payment_in_app,
              },
              {
                id: getID(price, TITLE_MP.gold, SETTING_DURATION.SIX_MONTHS),
                amount: getValues().gold_sixMonth_payment_in_app,
              },
              {
                id: getID(price, TITLE_MP.platinum, SETTING_DURATION.A_WEEK),
                amount: getValues().platinum_aWeek_payment_in_app,
              },
              {
                id: getID(price, TITLE_MP.platinum, SETTING_DURATION.ONE_MONTH),
                amount: getValues().platinum_oneMonth_payment_in_app,
              },
              {
                id: getID(
                  price,
                  TITLE_MP.platinum,
                  SETTING_DURATION.THREE_MONTHS,
                ),
                amount: getValues().platinum_threeMonth_payment_in_app,
              },
              {
                id: getID(
                  price,
                  TITLE_MP.platinum,
                  SETTING_DURATION.SIX_MONTHS,
                ),
                amount: getValues().platinum_sixMonth_payment_in_app,
              },
              {
                id: getID(price, TITLE_MP.vip, SETTING_DURATION.A_WEEK),
                amount: getValues().vip_aWeek_payment_in_app,
              },
              {
                id: getID(price, TITLE_MP.vip, SETTING_DURATION.ONE_MONTH),
                amount: getValues().vip_oneMonth_payment_in_app,
              },
              {
                id: getID(price, TITLE_MP.vip, SETTING_DURATION.THREE_MONTHS),
                amount: getValues().vip_threeMonth_payment_in_app,
              },
              {
                id: getID(price, TITLE_MP.vip, SETTING_DURATION.SIX_MONTHS),
                amount: getValues().vip_sixMonth_payment_in_app,
              },
            ],
          }),
        );
      },
    });
  };

  return (
    <Wrapper>
      <Content>
        <Title>{t('label.lofi_plus')}</Title>
        <Row>
          <Row>
            <InputNumberField<FormValues>
              controller={{
                control,
                name: 'plus_aWeek_payment_in_app',
              }}
              inputNumberProps={{
                type: 'number',
                min: 0,
              }}
              label={t('label.a_week')}
            />
            <InputNumberField<FormValues>
              controller={{
                control,
                name: 'plus_oneMonth_payment_in_app',
              }}
              inputNumberProps={{
                type: 'number',
                min: 0,
              }}
              label={t('label.one_month')}
            />
            <InputNumberField<FormValues>
              controller={{
                control,
                name: 'plus_threeMonth_payment_in_app',
              }}
              inputNumberProps={{
                type: 'number',
                min: 0,
              }}
              label={t('label.three_month')}
            />
          </Row>
          <Row>
            <InputNumberField<FormValues>
              controller={{
                control,
                name: 'plus_sixMonth_payment_in_app',
              }}
              inputNumberProps={{
                type: 'number',
                min: 0,
              }}
              label={t('label.six_month')}
            />
          </Row>
        </Row>
      </Content>
      <Content>
        <Title>{t('label.lofi_gold')}</Title>
        <Row>
          <Row>
            <InputNumberField<FormValues>
              controller={{
                control,
                name: 'gold_aWeek_payment_in_app',
              }}
              inputNumberProps={{
                type: 'number',
                min: 0,
              }}
              label={t('label.a_week')}
            />
            <InputNumberField<FormValues>
              controller={{
                control,
                name: 'gold_oneMonth_payment_in_app',
              }}
              inputNumberProps={{
                type: 'number',
                min: 0,
              }}
              label={t('label.one_month')}
            />
            <InputNumberField<FormValues>
              controller={{
                control,
                name: 'gold_threeMonth_payment_in_app',
              }}
              inputNumberProps={{
                type: 'number',
                min: 0,
              }}
              label={t('label.three_month')}
            />
          </Row>
          <Row>
            <InputNumberField<FormValues>
              controller={{
                control,
                name: 'gold_sixMonth_payment_in_app',
              }}
              inputNumberProps={{
                type: 'number',
                min: 0,
              }}
              label={t('label.six_month')}
            />
          </Row>
        </Row>
      </Content>
      <Content>
        <Title>{t('label.lofi_platinum')}</Title>
        <Row>
          <Row>
            <InputNumberField<FormValues>
              controller={{
                control,
                name: 'platinum_aWeek_payment_in_app',
              }}
              inputNumberProps={{
                type: 'number',
                min: 0,
              }}
              label={t('label.a_week')}
            />
            <InputNumberField<FormValues>
              controller={{
                control,
                name: 'platinum_oneMonth_payment_in_app',
              }}
              inputNumberProps={{
                type: 'number',
                min: 0,
              }}
              label={t('label.one_month')}
            />
            <InputNumberField<FormValues>
              controller={{
                control,
                name: 'platinum_threeMonth_payment_in_app',
              }}
              inputNumberProps={{
                type: 'number',
                min: 0,
              }}
              label={t('label.three_month')}
            />
          </Row>
          <Row>
            <InputNumberField<FormValues>
              controller={{
                control,
                name: 'platinum_sixMonth_payment_in_app',
              }}
              inputNumberProps={{
                type: 'number',
                min: 0,
              }}
              label={t('label.six_month')}
            />
          </Row>
        </Row>
      </Content>
      <Content>
        <Title>{t('label.lofi_vip')}</Title>
        <Row>
          <Row>
            <InputNumberField<FormValues>
              controller={{
                control,
                name: 'vip_aWeek_payment_in_app',
              }}
              inputNumberProps={{
                type: 'number',
                min: 0,
              }}
              label={t('label.a_week')}
            />
            <InputNumberField<FormValues>
              controller={{
                control,
                name: 'vip_oneMonth_payment_in_app',
              }}
              inputNumberProps={{
                type: 'number',
                min: 0,
              }}
              label={t('label.one_month')}
            />
            <InputNumberField<FormValues>
              controller={{
                control,
                name: 'vip_threeMonth_payment_in_app',
              }}
              inputNumberProps={{
                type: 'number',
                min: 0,
              }}
              label={t('label.three_month')}
            />
          </Row>
          <Row>
            <InputNumberField<FormValues>
              controller={{
                control,
                name: 'vip_sixMonth_payment_in_app',
              }}
              inputNumberProps={{
                type: 'number',
                min: 0,
              }}
              label={t('label.six_month')}
            />
          </Row>
        </Row>
      </Content>
      <Divider />
      <Button
        disabled={!formState.isDirty}
        type="primary"
        key={`action`}
        onClick={handleConfirm}
        icon={<SaveOutlined />}
      >
        {t('button.save')}
      </Button>
    </Wrapper>
  );
};

const Title = styled.h3`
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 20px;
`;
const Wrapper = styled.div``;

const Content = styled.div`
  margin-bottom: 20px;
  .input-field {
    margin-right: 54px;
  }
`;

export default PaymentInApp;
