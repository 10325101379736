import HTTPRequest from '../index';

export type Credentials = {
  email?: string;
  password?: string;
};

const apiPath = '/admin/auth/login';

class Auth extends HTTPRequest {
  login(value: Credentials) {
    return this.post<Credentials>(`${apiPath}`, { ...value });
  }
}

export default new Auth();
