import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, BadgeProps } from 'antd';

import { USER_STATUS } from 'src/enum/user';

type Props = {
  status: USER_STATUS;
};

const UserStatus: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation();

  const getProps = (): BadgeProps => {
    switch (status) {
      case USER_STATUS.BLOCKED:
        return { status: 'error', text: t('option.block') };
      case USER_STATUS.NORMAL:
        return { status: 'success', text: t('option.normal') };
      default:
        return { color: 'default', text: t('option.all') };
    }
  };

  return <Badge {...getProps()} />;
};

export default UserStatus;
