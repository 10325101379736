import React from 'react';

import { IRouter } from 'src/interfaces/route';
import Layout from 'src/components/layout';
import loadable from 'src/utils/Loadable';
import { t } from 'src/libs/i18n';
import { PATH_COUPLE } from 'src/pages/matchCouple';
import { PATH_SWIPE } from 'src/pages/matchSwipe';

const MatchRoute: IRouter[] = [
  {
    element: <Layout />,
    children: [
      {
        path: PATH_SWIPE,
        element: loadable(() => import('src/pages/matchSwipe')),
        title: t('title.swipe_interaction'),
      },
      {
        path: PATH_COUPLE,
        element: loadable(() => import('src/pages/matchCouple')),
      },
    ],
  },
];

export default MatchRoute;
