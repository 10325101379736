import React from 'react';
import { Card, Table as AntTable } from 'antd';
import { useTranslation } from 'react-i18next';

import useTable from 'src/hook/useTable';
import column from './column';

type Props = {
  loading: boolean;
  entities: Response.ReportEntity[];
};

const Table: React.FC<Props> = ({ entities, loading }) => {
  const { t } = useTranslation();
  const { Selected, pagination, rowSelected, onChange } =
    useTable<Response.ReportEntity>();

  return (
    <Card
      title={
        <span>
          <Selected>
            {t('rows_selected', {
              count: rowSelected.length,
            })}
          </Selected>
        </span>
      }
    >
      <div>
        <AntTable
          rowKey="id"
          loading={loading}
          columns={column(t)}
          dataSource={entities || []}
          pagination={pagination}
          onChange={onChange}
          scroll={{ x: 'max-content' }}
        />
      </div>
    </Card>
  );
};

export default Table;
