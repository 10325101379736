import React, { useEffect } from 'react';

import { ContentWrapper } from 'src/components/common';
import SettingMemberContainer from 'src/containers/SettingMember';
import { fetchResource } from 'src/useCase/settingMemberPackage';
import { useAppDispatch } from 'src/store';

const Page: App.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchResource());
  }, []);

  return (
    <ContentWrapper title="">
      <SettingMemberContainer />
    </ContentWrapper>
  );
};

export const PATH = '/setting/member' as const;
export default Page;
