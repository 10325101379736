export enum USER_SEARCH_TYPE {
  EMAIL = 'email',
  USER_ID = 'userId',
}

export enum USER_STATUS {
  ALL = '',
  NORMAL = 1,
  BLOCKED = 2,
}

export enum USER_MEMBER {
  ALL = '',
  NORMAL = '1',
  PLUS = '2',
  GOLD = '3',
  PLATINUM = '4',
  VIP = '5',
}

export enum USER_DETAIL {
  PERSONAL_INFORMATION = 'personalinfomation',
  INTERACTION = 'interaction',
}

export enum USER_GENDER {
  WOMAN = 1,
  MAN = 2,
  OTHER = 3,
}
export enum USER_SHOW_ME {
  WOMAN = 1,
  MAN = 2,
  EVERYONE = 3,
}

export enum USER_PERIOD_SEARCH {
  SIGNUP_DATE = 'sign_update',
  LAST_VISIT = 'last_visit',
}
