import React from 'react';
import styled from 'styled-components';
import { Button, Card, Space, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import useResolve, { FormValues } from './useAction';
import RowDetail from 'src/components/common/RowDetail';
import ImagePreview from 'src/components/common/ImagePreview';
import ReportStatus from 'src/components/badge/ReportStatus';
import { TextAreaField } from 'src/components/form';
import { useAppDispatch } from 'src/store';
import { updateSolution } from 'src/useCase/reportDetail';
import {
  checkSubReason,
  formatDate,
  renderReportReason,
  renderSupReason,
} from 'src/utils/Helpers';

type Props = {
  loading: boolean;
  entities: Response.ReportDetailEntity | null;
};

const ReportDetail: React.FC<Props> = ({ entities, loading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { form } = useResolve();
  const { control, formState } = form;
  const { id } = useParams();

  const handleReportResolve = (solution: string) => {
    Modal.confirm({
      title: t('message.setting_confirm'),
      onOk: async () => {
        await dispatch(
          updateSolution({ id: String(id), payload: { solution } }),
        );
      },
    });
  };

  return (
    <Wrapper>
      <Card loading={loading}>
        <RowDetail
          title={t('label.reporterName')}
          value={entities?.reporter_name || 'empty'}
        />
        <RowDetail
          title={t('label.reporterEmail')}
          value={entities?.reporter_email || 'empty'}
        />
        <RowDetail
          title={t('label.reporteeName')}
          value={entities?.reportee_name || 'empty'}
        />
        <RowDetail
          title={t('label.reporteeEmail')}
          value={entities?.reportee_email || 'empty'}
        />
        <RowDetail
          title={t('label.reason')}
          value={renderReportReason(entities?.reason)}
        />
        <RowDetail
          title={t('label.subReason')}
          value={renderSupReason(
            entities?.reason,
            Number(entities?.sub_reason) || 0,
          )}
        />
        {checkSubReason(entities?.reason, entities?.sub_reason) && (
          <>
            <RowDetail
              title={t('label.content')}
              value={entities?.content || 'empty'}
            />
            <RowDetail
              title={t('label.attactmentPhotos')}
              value={
                <ImagePreview
                  list={entities?.reports_url || []}
                  srcKey={'path'}
                />
              }
              layout={{ valueCol: { span: 20 } }}
            />
          </>
        )}
        <RowDetail
          title={t('label.reportDate')}
          value={formatDate(entities?.created_at)}
        />
        <RowDetail
          title={t('label.resolveDate')}
          value={formatDate(entities?.resolved_at) || 'empty'}
        />
        <RowDetail
          title={t('label.activityStatus')}
          value={<ReportStatus status={entities?.activity_status} />}
        />
        {entities?.solution ? (
          <RowDetail
            title={t('label.solution')}
            value={<span>{entities?.solution}</span>}
          />
        ) : (
          <RowDetail
            title={t('label.solution')}
            value={
              <TextAreaField<FormValues>
                controller={{
                  name: 'solution',
                  control,
                }}
              />
            }
          />
        )}
      </Card>
      <Space>
        <Button onClick={() => navigate('/report')}>{t('button.back')}</Button>
        <Button
          onClick={() => handleReportResolve(form.getValues().solution)}
          disabled={!!entities?.solution || !formState.isDirty}
        >
          {t('button.resolve')}
        </Button>
      </Space>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ant-card {
    margin-bottom: 10px;
  }
  .ant-form-item-label {
    text-align: left;
  }
  .ant-radio-button-wrapper {
    margin-right: 8px;
  }
`;

export default ReportDetail;
