import React from 'react';
import styled from 'styled-components';
import { Button, Divider, Row } from 'antd';

import useFormSetting, { FormValues } from './useForm';
import { t } from 'src/libs/i18n';
import { InputNumberField } from 'src/components/form';
import { SaveOutlined } from '@ant-design/icons';
import modalConfirm from 'src/utils/modalConfirm';
import { useAppDispatch } from 'src/store';
import { updateSettingExtraPrices } from 'src/useCase/settingExtraPrice';

type Props = {
  values: Response.SettingExtraPrice[] | undefined;
};

const BNB: React.FC<Props> = ({ values }) => {
  const { form } = useFormSetting(values);
  const { control, formState, getValues } = form;
  const dispatch = useAppDispatch();

  const handleConfirm = () => {
    modalConfirm({
      title: t('message.setting_confirm'),
      okText: t('button.confirm'),
      style: { textAlign: 'center' },
      onOk: async () => {
        await dispatch(
          updateSettingExtraPrices({
            prices: [
              {
                id: values?.[0]?.id,
                amount: getValues().five_times_bnb,
              },
              {
                id: values?.[1]?.id,
                amount: getValues().fifteen_times_bnb,
              },
              {
                id: values?.[2]?.id,
                amount: getValues().thirty_times_bnb,
              },
            ],
          }),
        );
      },
    });
  };

  return (
    <Wrapper>
      <Content>
        <Title>{t('label.service')}</Title>
        <Row>
          <Row>
            <InputNumberField<FormValues>
              controller={{
                control,
                name: 'five_times_bnb',
              }}
              inputNumberProps={{
                type: 'number',
                min: 0,
              }}
              label={t('label.five_times')}
            />
            <InputNumberField<FormValues>
              controller={{
                control,
                name: 'fifteen_times_bnb',
              }}
              inputNumberProps={{
                type: 'number',
                min: 0,
              }}
              label={t('label.fifteen_times')}
            />
          </Row>
          <Row>
            <InputNumberField<FormValues>
              controller={{
                control,
                name: 'thirty_times_bnb',
              }}
              inputNumberProps={{
                type: 'number',
                min: 0,
              }}
              label={t('label.thirty_times')}
            />
          </Row>
        </Row>
      </Content>
      <Divider />
      <Button
        disabled={!formState.isDirty}
        type="primary"
        onClick={handleConfirm}
        icon={<SaveOutlined />}
      >
        {t('button.save')}
      </Button>
    </Wrapper>
  );
};

const Title = styled.h3`
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 20px;
`;
const Wrapper = styled.div``;

const Content = styled.div`
  margin-bottom: 20px;
  .input-field {
    margin-right: 54px;
  }
`;

export default BNB;
