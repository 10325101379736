import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentWrapper } from 'src/components/common';
import MatchCoupleContainer from 'src/containers/MatchCouple';
import { fetchResource } from 'src/useCase/matchCouple';
import useQueryUrl from 'src/hook/useQueryUrl';
import { useAppDispatch } from 'src/store';

const Page: App.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { query } = useQueryUrl();

  useEffect(() => {
    dispatch(fetchResource(query));
  }, [query]);

  return (
    <ContentWrapper title={t('title.match_couple')}>
      <MatchCoupleContainer />
    </ContentWrapper>
  );
};

export const PATH_COUPLE = '/match/couple' as const;
export default Page;
