import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Button, Card, Col, Row } from 'antd';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

import {
  DatePickerField,
  InputField,
  RadioGroupField,
  SelectField,
} from 'src/components/form';
import useSearch, { FormValues } from './useSearch';
import {
  user_status,
  search_type,
  user_member_package,
  range_picker,
  user_period_search,
  user_gender,
} from 'src/constants/optionsSelect/user';
import { onChangeQuickPeriod } from 'src/utils/Helpers';
import { DATE_TIME_FORMAT } from 'src/constants/app';

const Search = () => {
  const { t } = useTranslation();
  const { xl, xxl } = useBreakpoint();
  const { form, resetForm, handleSearch } = useSearch();
  const { control, setValue } = form;

  return (
    <Wrapper>
      <Card
        title={
          <Fragment>
            <FilterOutlined />
            <span>{t('title.basic_search')}</span>
          </Fragment>
        }
      >
        <Row gutter={24}>
          <Col xl={8} xxl={6} md={10}>
            <SelectField<FormValues>
              label={t('label.search_type')}
              controller={{ name: 'search_type', control }}
              selectProps={{
                options: search_type(t),
              }}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            />
          </Col>
          <Col xl={10} md={14}>
            <InputField<FormValues>
              controller={{ name: 'search_text', control }}
              inputProps={{ prefix: <SearchOutlined />, allowClear: true }}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xl={8} xxl={6} md={10}>
            <SelectField<FormValues>
              label={t('label.status')}
              controller={{ name: 'status', control }}
              selectProps={{
                options: user_status(t),
              }}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            />
          </Col>
          <Col xl={8} xxl={6} md={10}>
            <SelectField<FormValues>
              label={t('label.member_package')}
              controller={{ name: 'member', control }}
              selectProps={{
                options: user_member_package(t),
              }}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 12 }}
            />
          </Col>
          <Col xl={2} xxl={0} />
          {xxl && (
            <Col xl={5} xxl={4} md={7}>
              <SelectField<FormValues>
                label={t('label.gender')}
                controller={{ name: 'gender', control }}
                selectProps={{
                  options: user_gender(t),
                }}
                labelCol={xxl ? { span: 7 } : { span: 7 }}
                wrapperCol={{ span: 17 }}
              />
            </Col>
          )}

          {xl && (
            <React.Fragment>
              <Col span={3}>
                <Button
                  type="primary"
                  style={{ width: '100%' }}
                  onClick={handleSearch}
                >
                  {t('button.search')}
                </Button>
              </Col>
              <Col span={3}>
                <Button
                  type="primary"
                  style={{ width: '100%' }}
                  onClick={resetForm}
                >
                  {t('button.reset')}
                </Button>
              </Col>
            </React.Fragment>
          )}
        </Row>
        {!xxl && (
          <Row gutter={24}>
            <Col xl={8} xxl={6} md={10}>
              <SelectField<FormValues>
                label={t('label.gender')}
                controller={{ name: 'gender', control }}
                selectProps={{
                  options: user_gender(t),
                }}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              />
            </Col>
          </Row>
        )}
        <Row gutter={24}>
          <Col xl={8} xxl={6} md={10}>
            <SelectField<FormValues>
              label={t('label.period_search')}
              selectProps={{
                options: user_period_search(t),
              }}
              controller={{ name: 'period_search', control }}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            />
          </Col>
          <Col xl={5} md={7}>
            <DatePickerField<FormValues>
              controller={{
                name: 'from_date',
                control,
                rules: {
                  onChange: () => {
                    setValue('quick_period_search', undefined);
                  },
                },
              }}
              datePickerProps={{
                format: DATE_TIME_FORMAT,
              }}
            />
          </Col>
          <Col xl={5} md={7}>
            <DatePickerField<FormValues>
              controller={{
                name: 'to_date',
                control,
                rules: {
                  onChange: (e) => {
                    setValue('quick_period_search', undefined);
                  },
                },
              }}
              datePickerProps={{
                format: DATE_TIME_FORMAT,
              }}
            />
          </Col>
          <Col xxl={8}>
            <RadioGroupField<FormValues>
              controller={{
                name: 'quick_period_search',
                control,
                rules: { onChange: (e) => onChangeQuickPeriod(e, setValue) },
              }}
              radioGroupProps={{
                options: range_picker(t),
                optionType: 'button',
              }}
            />
          </Col>
          {!xl && (
            <React.Fragment>
              <Col span={6}>
                <Button
                  type="primary"
                  style={{ width: '100%' }}
                  onClick={handleSearch}
                >
                  {t('button.search')}
                </Button>
              </Col>
              <Col span={6}>
                <Button
                  type="primary"
                  style={{ width: '100%' }}
                  onClick={resetForm}
                >
                  {t('button.reset')}
                </Button>
              </Col>
            </React.Fragment>
          )}
        </Row>
      </Card>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ant-radio-button-wrapper {
    margin-right: 6px;
  }
  .ant-form-item-label {
    text-align: left;
  }
`;

export default Search;
