import React, { useCallback } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import Notification from 'src/components/application/Notification';
import { useAppSelector } from 'src/store';
import notifications from 'src/store/ui/notification';

const ContainerNotification = () => {
  const dispatch = useDispatch();

  const handleCloseNotification = useCallback(() => {
    dispatch(notifications.offNotification());
  }, [dispatch]);

  const notification = useAppSelector(
    (state) => state.ui.notification,
    (left, right) => _.isEqual(left, right),
  );

  const notificationProps = {
    handleCloseNotification,
    ...notification,
  };

  return <Notification {...notificationProps} />;
};

export default ContainerNotification;
