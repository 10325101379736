import React from 'react';

import Main from './Main';
import { useAppSelector } from 'src/store';

const Container = () => {
  const props = useAppSelector((state) => state.domain.setting.extraPrice);

  return <Main {...props} />;
};

export default Container;
