import React from 'react';
import styled from 'styled-components';
import { Card } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import useFormSetting, { FormValues } from './useForm';
import USDT from '../PaymentMethods/USDT';
import BNB from '../PaymentMethods/BNB';
import LOLO from '../PaymentMethods/LOLO';
import { config_option } from 'src/constants/optionsSelect/memberPackage';
import { t } from 'src/libs/i18n';
import { RadioGroupField } from 'src/components/form';
import { CONFIG_OPTION } from 'src/enum/memberPackage';
import LoadingPage from 'src/components/application/LoadingPage';
import { KIND_PAYMENT } from 'src/enum/settingMemberPackage';
import PaymentInApp from '../PaymentMethods/PaymentInApp';

type Props = {
  loading: boolean;
  entities: Response.SettingExtraPriceEntity;
};

const Main: React.FC<Props> = ({ entities, loading }) => {
  const { form } = useFormSetting();
  const { control, watch } = form;

  const cardTitle = (
    <React.Fragment>
      <EditOutlined />
      <span style={{ marginLeft: '8px' }}>{t('title.setting_superlike')}</span>
    </React.Fragment>
  );

  const renderPaymentOption = (option: string) => {
    switch (option) {
      case CONFIG_OPTION.USDT:
        return <USDT values={entities[KIND_PAYMENT.USDT]} />;
      case CONFIG_OPTION.BNB:
        return <BNB values={entities[KIND_PAYMENT.BNB]} />;
      case CONFIG_OPTION.LOLO:
        return <LOLO values={entities[KIND_PAYMENT.LOLO]} />;
      case CONFIG_OPTION.PAYMENT_IN_APP:
        return <PaymentInApp values={entities[KIND_PAYMENT.APP]} />;
      default:
        return;
    }
  };

  return (
    <Card title={cardTitle}>
      {loading ? (
        <LoadingPage />
      ) : (
        <React.Fragment>
          <Content>
            <Title fontSize="24px">{t('label.payment_method')}</Title>
            <RadioGroupField<FormValues>
              controller={{ name: 'options', control }}
              radioGroupProps={{
                options: config_option(t),
              }}
            />
          </Content>
          <Content>{renderPaymentOption(watch().options)}</Content>
        </React.Fragment>
      )}
    </Card>
  );
};

const Title = styled.h3`
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 20px;
`;

const Content = styled.div`
  margin-bottom: 20px;
  .input-field {
    margin-right: 54px;
  }
`;

export default Main;
