import { useForm } from 'react-hook-form';
import { CONFIG_OPTION } from '../../../enum/memberPackage';

export type FormValues = {
  options: string;
};

const useFormSetting = () => {
  const defaultValues: FormValues = {
    options: CONFIG_OPTION.USDT,
  };
  const form = useForm<FormValues>({
    defaultValues,
  });

  return {
    form,
  };
};

export default useFormSetting;
