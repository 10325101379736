import { useForm } from 'react-hook-form';

import { Duration } from 'src/enum/settingMemberPackage';

export type FormValues = {
  five_times_lolo?: string;
  fifteen_times_lolo?: string;
  thirty_times_lolo?: string;
};

type ValueForm = Response.SettingExtraPrice[] | undefined;

const useFormSetting = (values: ValueForm) => {
  const defaultValues: FormValues = {
    five_times_lolo: values?.[Duration.five]?.amount,
    fifteen_times_lolo: values?.[Duration.fifteen]?.amount,
    thirty_times_lolo: values?.[Duration.thirty]?.amount,
  };
  const form = useForm<FormValues>({
    defaultValues,
  });
  return {
    form,
  };
};

export default useFormSetting;
