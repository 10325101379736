import React from 'react';
import { ConfigProvider } from 'antd';
import ko_KR from 'antd/es/locale/ko_KR';
import en_US from 'antd/es/locale/en_US';
import { useRoutes } from 'react-router-dom';

import { useAppSelector } from './store';
import routeConfig from 'src/routes';
import Notification from './containers/Notification';

function App() {
  const lang = useAppSelector((state) => state.ui.i18n.lang);
  const routes = useRoutes(routeConfig);

  return (
    <ConfigProvider
      locale={lang === 'ko' ? ko_KR : en_US}
      componentSize="middle"
    >
      {routes}
      <Notification />
    </ConfigProvider>
  );
}

export default App;
