export enum REPORT_SEARCH_TYPE {
  EMAIL = 'email',
  REPORT_NAME = 'name',
}
export enum REPORT_OPTION_TYPE {
  REPORT_NAME = '1',
  EMAIL = '2',
}

export enum REPORT_REASON {
  ALL = '',
  FAKE_PROFILE_OR_SPAM = 1,
  IMAPPROPRIATE_MESSAGE = 2,
  IMAPPROPRIATE_PROFILE_PHOTO = 3,
  OFFLINE_BEHAVIOR = 4,
}
export enum REPORT_FAKE_PROFILE_OR_SPAM {
  THIS_USER_SENT_ME_A_SUSPICIOUS_LINK = 1,
  THIS_USER_ASKED_ME_FOR_MONEY = 2,
  BELIEVE_THE_PROFILE_IS_FAKE = 3,
  NOT_INTERESTED_IN_THIS_PERSON = 4,
  OTHER = 5,
}
export enum REPORT_IMAPPROPRIATE_MESSAGE {
  BEING_RUDE_HARASSING_ME = 1,
  SENT_ME_SEXUAL_MESSAGES = 2,
  MESSAGE_CONTAINS_HATE_SPEECH = 3,
  OTHER = 4,
}
export enum REPORT_IMAPPROPRIATE_PROFILE_PHOTO {
  THIS_PHOTO_CONTAINS_NUDITY = 1,
  THIS_PHOTO_IS_VIOLENT = 2,
  DO_NOT_LIKE_THIS_PHOTO = 3,
  THIS_IS_A_FAKE_PROFILE = 4,
  OTHER = 5,
}
export enum REPORT_OFFLINE_BEHAVIOR {
  LIKE_TO_REPORT_THEM = 1,
  I_KNOW_THIS_PERSON = 2,
  OTHER = 3,
}

export enum REPORT_STATUS {
  NEW = 1,
  COMPLETED = 2,
}

export enum REPORT_DETAIL {
  REPORT_DETAIL = 'reportDetail',
}
