import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { formatDate, renderUserGender } from 'src/utils/Helpers';
import UserStatus from 'src/components/badge/UserStatus';
import imgfake from 'src/assets/logo.png';

const column: App.ColumnsFnc<Response.UserEntity> = (t, sortInfo) => [
  {
    title: t('column.name'),
    key: 'name',
    render: (_, row) => {
      return (
        <div>
          <AvtImg
            src={row.avatar_url ? row.avatar_url : imgfake}
            onError={(e) => {
              e.currentTarget.src = imgfake;
            }}
          />
          <Link to={`/user/${row.id}`}>
            <span>{row.fullname || '-'}</span>
          </Link>
        </div>
      );
    },
  },
  {
    title: t('column.userId'),
    dataIndex: 'id',
    key: 'userId',
  },
  {
    title: t('column.email'),
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: t('column.gender'),
    key: 'gender',
    render: (_, row) => {
      return renderUserGender(row?.gender);
    },
  },
  {
    title: t('column.location'),
    key: 'location',

    render: (_, row) => {
      return <span>{row?.address || '-'}</span>;
    },
  },
  {
    title: t('column.member_package'),
    key: 'package',
    render: (_, row) => {
      return <span>{row.package.attributes.title || '-'}</span>;
    },
  },
  {
    title: t('column.fake_user'),
    key: 'fake_user',
    render: (_, row) => {
      return <span>{row.is_fake ? 'Yes' : 'No'}</span>;
    },
  },
  {
    title: t('column.last_visit'),
    key: 'lastVisit',
    render: (_, row) => {
      return formatDate(row.last_active);
    },
  },
  {
    title: t('column.signup_date'),
    key: 'signupDate',
    render: (_, row) => {
      return formatDate(row.created_at);
    },
  },
  {
    title: t('column.activity_status'),
    key: 'status',
    render: (_, row) => {
      return <UserStatus status={row.status} />;
    },
  },
];

const AvtImg = styled.img`
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border-radius: 50%;
`;
export default column;
