import React, { useEffect } from 'react';

import ConfigSuperlikeContainer from 'src/containers/ConfigSuperlike';
import { ContentWrapper } from 'src/components/common';
import { fetchResource } from 'src/useCase/settingExtraPrice';
import { useAppDispatch } from 'src/store';
import { CONFIG_SETTING } from 'src/enum/settingMemberPackage';

const Page: App.FC<SearchParams.Users> = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchResource({ type: CONFIG_SETTING.LIKE }));
  }, []);

  return (
    <ContentWrapper title="">
      <ConfigSuperlikeContainer />
    </ContentWrapper>
  );
};

export const PATHSUPERLIKE = '/setting/superlike' as const;
export default Page;
