import { MEMBER_PACKAGE_SEARCH_TYPE } from 'src/enum/memberPackage';
import { MATCH_OPTION_TYPE } from 'src/enum/match';

export const search_type: App.OptionsFnc = (t) => [
  {
    label: t('option.email'),
    value: 'email',
  },
  {
    label: t('option.user_ID'),
    value: 'userId',
  },
];
export const member_package_search_type: App.OptionsFnc = (t) => [
  {
    label: t('option.email'),
    value: MEMBER_PACKAGE_SEARCH_TYPE.EMAIL,
  },
  {
    label: t('option.user_name'),
    value: MEMBER_PACKAGE_SEARCH_TYPE.USER_NAME,
  },
];
export const report_search_type: App.OptionsFnc = (t) => [
  {
    label: t('option.email'),
    value: 'email',
  },
  {
    label: t('option.user_name'),
    value: 'name',
  },
];
export const match_search_type: App.OptionsFnc = (t) => [
  {
    label: t('option.email'),
    value: MATCH_OPTION_TYPE.EMAIL,
  },
  {
    label: t('option.user_name'),
    value: MATCH_OPTION_TYPE.NAME,
  },
];
export const range_picker: App.OptionsFnc = (t) => [
  { label: t('button.today'), value: 'today' },
  { label: t('button.yesterday'), value: 'yesterday' },
  { label: t('button.week'), value: 'week' },
  { label: t('button.month'), value: 'month' },
];
