import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import ReportStatus from 'src/components/badge/ReportStatus';
import imgfake from 'src/assets/logo.png';
import { formatDate, renderReportReason } from 'src/utils/Helpers';

const column: App.ColumnsFnc<Response.ReportEntity> = (t, sortInfo) => [
  {
    title: t('column.report_id'),
    key: 'reportId',
    render: (_, row) => {
      return <span>{row.id || '-'}</span>;
    },
  },
  {
    title: t('column.reporter_name'),
    key: 'reporterName',
    render: (_, row) => {
      return (
        <div>
          <AvtImg
            src={row.avatar_url ? row.avatar_url : imgfake}
            onError={(e) => {
              e.currentTarget.src = imgfake;
            }}
          />
          <Link to={`/report/${row.id}`}>
            <span>{row.reporter_name || '-'}</span>
          </Link>
        </div>
      );
    },
  },
  {
    title: t('column.reporter_email'),
    key: 'reporterEmail',
    render: (_, row) => {
      return <span>{row.reporter_email || '-'}</span>;
    },
  },
  {
    title: t('column.reportee_name'),
    key: 'reporteeName',
    render: (_, row) => {
      return <span>{row.reportee_name || '-'}</span>;
    },
  },
  {
    title: t('column.reportee_email'),
    key: 'reporteeEmail',
    render: (_, row) => {
      return <span>{row.reportee_email || '-'}</span>;
    },
  },
  {
    title: t('column.reason'),
    dataIndex: 'reason',
    key: 'reason',
    render: (_, row) => {
      return renderReportReason(row.reason);
    },
  },
  {
    title: t('column.report_date'),
    key: 'reportDate',
    render: (_, row) => {
      return formatDate(row.created_at || '-');
    },
  },
  {
    title: t('column.status'),
    key: 'status',
    render: (_, row) => {
      return <ReportStatus status={row.status} />;
    },
  },
];
const AvtImg = styled.img`
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border-radius: 50%;
`;

export default column;
