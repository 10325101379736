import { useForm } from 'react-hook-form';

import { SETTING_DURATION, TITLE_MP } from 'src/enum/settingMemberPackage';
import { getPrices } from 'src/utils/Helpers';

export type FormValues = {
  plus_aWeek_bnb: string;
  plus_oneMonth_bnb: string;
  plus_threeMonth_bnb: string;
  plus_sixMonth_bnb: string;

  gold_aWeek_bnb: string;
  gold_oneMonth_bnb: string;
  gold_threeMonth_bnb: string;
  gold_sixMonth_bnb: string;

  platinum_aWeek_bnb: string;
  platinum_oneMonth_bnb: string;
  platinum_threeMonth_bnb: string;
  platinum_sixMonth_bnb: string;

  vip_aWeek_bnb: string;
  vip_oneMonth_bnb: string;
  vip_threeMonth_bnb: string;
  vip_sixMonth_bnb: string;
};
type Valuebnb = Array<Response.SettingExtraPrice[]>;

const useFormSetting = (price: Valuebnb) => {
  const defaultValues: FormValues = {
    plus_aWeek_bnb:
      getPrices(price, TITLE_MP.plus, SETTING_DURATION.A_WEEK) || '',
    plus_oneMonth_bnb:
      getPrices(price, TITLE_MP.plus, SETTING_DURATION.ONE_MONTH) || '',
    plus_threeMonth_bnb:
      getPrices(price, TITLE_MP.plus, SETTING_DURATION.THREE_MONTHS) || '',
    plus_sixMonth_bnb:
      getPrices(price, TITLE_MP.plus, SETTING_DURATION.SIX_MONTHS) || '',
    gold_aWeek_bnb:
      getPrices(price, TITLE_MP.gold, SETTING_DURATION.A_WEEK) || '',
    gold_oneMonth_bnb:
      getPrices(price, TITLE_MP.gold, SETTING_DURATION.ONE_MONTH) || '',
    gold_threeMonth_bnb:
      getPrices(price, TITLE_MP.gold, SETTING_DURATION.THREE_MONTHS) || '',
    gold_sixMonth_bnb:
      getPrices(price, TITLE_MP.gold, SETTING_DURATION.SIX_MONTHS) || '',
    platinum_aWeek_bnb:
      getPrices(price, TITLE_MP.platinum, SETTING_DURATION.A_WEEK) || '',
    platinum_oneMonth_bnb:
      getPrices(price, TITLE_MP.platinum, SETTING_DURATION.ONE_MONTH) || '',
    platinum_threeMonth_bnb:
      getPrices(price, TITLE_MP.platinum, SETTING_DURATION.THREE_MONTHS) || '',
    platinum_sixMonth_bnb:
      getPrices(price, TITLE_MP.platinum, SETTING_DURATION.SIX_MONTHS) || '',
    vip_aWeek_bnb:
      getPrices(price, TITLE_MP.vip, SETTING_DURATION.A_WEEK) || '',
    vip_oneMonth_bnb:
      getPrices(price, TITLE_MP.vip, SETTING_DURATION.ONE_MONTH) || '',
    vip_threeMonth_bnb:
      getPrices(price, TITLE_MP.vip, SETTING_DURATION.THREE_MONTHS) || '',
    vip_sixMonth_bnb:
      getPrices(price, TITLE_MP.vip, SETTING_DURATION.SIX_MONTHS) || '',
  };

  const form = useForm<FormValues>({
    defaultValues,
    mode: 'all',
  });
  return {
    form,
  };
};

export default useFormSetting;
