import { createAsyncThunk } from '@reduxjs/toolkit';

import settingRequest, {
  updateMemberPackagePrices,
} from 'src/api/requests/setting';
import settingMemberAction from 'src/store/domain/settingMemberPackage/list';

export const fetchResource = createAsyncThunk<void, string | undefined>(
  'get_setting_member_package',
  async (_, { dispatch }) => {
    try {
      dispatch(settingMemberAction.updateLoading(true));

      const res = await settingRequest.getMemberPackagePrices();
      const { data } = res.data;

      dispatch(settingMemberAction.updateEntities(data));

      dispatch(settingMemberAction.updateLoading(false));
    } catch (err) {
      dispatch(settingMemberAction.updateLoading(false));
    }
  },
);
export const updateSettingMPPrices = createAsyncThunk<
  any,
  updateMemberPackagePrices
>(
  'update_mp_prices',
  async ({ prices }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      await settingRequest.updateMemberPackagePrices({ prices });

      await dispatch(fetchResource());

      return fulfillWithValue({ type: 'success', message: 'Success' });
    } catch (e) {
      return rejectWithValue({ type: 'error', message: 'Error' });
    }
  },
);
