import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

import memberPackageAction from 'src/store/domain/memberPackage/list';
import appAction from 'src/store/ui/app';
import memberPackageRequest from 'src/api/requests/memberPackage';

interface FetchResource extends SearchParams.MemberPackage {}

const getParams = (args: FetchResource) => {
  const {
    limit,
    page,
    search_type,
    period_search,
    search_text,
    status,
    duration,
    member_package,
    from_date,
    to_date,
  } = args;

  const params: Payload.MemberPackage = {
    type: search_type,
    limit,
    keyword: search_text,
    page,
    status,
    duration,
    package: member_package,
    period_search,
    created_at_from: from_date,
    created_at_to: to_date,
  };
  return params;
};
export const fetchResource = createAsyncThunk<void, FetchResource>(
  'get_memberPackages',
  async (args, { dispatch }) => {
    try {
      dispatch(memberPackageAction.updateLoading(true));
      const params = getParams(args);
      const res = await memberPackageRequest.getList(
        _.omitBy(params, _.isEmpty),
      );
      const { data, meta } = res.data;

      if (data) {
        dispatch(memberPackageAction.updateEntities(data.user_info.data));
      }

      if (meta) {
        dispatch(
          appAction.updatePagination({
            total: meta.total,
            current: meta.current_page,
          }),
        );
      }
      dispatch(memberPackageAction.updateLoading(false));
    } catch (err) {
      dispatch(memberPackageAction.updateLoading(false));
    }
  },
);
