export enum SETTING_PRICE_INDEX {
  CRYPTO = '1',
  INAPP = '2',
  CREDIT = '3',
}
export enum PAYMENT_TYPE {
  BNB = '1',
  USDT = '2',
  USD = '3',
  BUSD = '5',
}
export enum SETTING_OPTION {
  USDT = '1',
  BUSD = '2',
  PAYMENT_IN_APP = '3',
  CREDIT_CARD = '4',
  BNB = '5',
}
export enum PAYMENT_METHODS {
  CRYPTO = '1',
  INAPP = '2',
  CREDIT = '3',
}

export enum SETTING_DURATION {
  ONE_MONTH = '0',
  THREE_MONTHS = '1',
  SIX_MONTHS = '2',
  A_WEEK = '3',
}

export type PriceIndex =
  | SETTING_PRICE_INDEX.CRYPTO
  | SETTING_PRICE_INDEX.CREDIT
  | SETTING_PRICE_INDEX.INAPP;

export type PriceChildrenIndex =
  | PAYMENT_TYPE.USDT
  | PAYMENT_TYPE.BUSD
  | PAYMENT_TYPE.USD;

type Transaction = {
  id: number;
  duration: number;
  amount?: string;
};

export type ChildPrice = Partial<Record<PriceChildrenIndex, Transaction[]>>;

//MP: MemberPackage
export enum TITLE_MP {
  plus = 0,
  gold = 1,
  platinum = 2,
  vip = 3,
}
export enum KIND_PAYMENT {
  BNB = '1',
  USDT = '2',
  APP = '3',
  LOLO = '4',
}

export enum CONFIG_SETTING {
  LIKE = '1',
  SPOTLIGHT = '2',
}

export enum Duration {
  five,
  fifteen,
  thirty,
}
