import React from 'react';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { Link, NavLink } from 'react-router-dom';
import {
  HomeOutlined,
  ProfileOutlined,
  TeamOutlined,
  HeartOutlined,
  ExclamationCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { t } from 'src/libs/i18n';

export const items: ItemType[] = [
  {
    label: <Link to="/">{t('title.dashboard')}</Link>,
    key: '/',
    icon: <HomeOutlined />,
  },
  {
    label: <Link to="/user">{t('title.user_management')}</Link>,
    key: '/user',
    icon: <ProfileOutlined />,
  },
  {
    label: t('title.match_management'),
    key: '/match',
    icon: <HeartOutlined />,
    children: [
      {
        label: (
          <NavLink to="/match/swipe">{t('title.swipe_interaction')}</NavLink>
        ),
        key: '/match/swipe',
      },
      {
        label: <NavLink to="/match/couple">{t('title.match_couple')}</NavLink>,
        key: '/match/couple',
      },
    ],
  },
  {
    label: <Link to="/member">{t('title.member_package')}</Link>,
    key: '/member',
    icon: <TeamOutlined />,
  },
  {
    label: <Link to="/report">{t('title.report_management')}</Link>,
    key: '/report',
    icon: <ExclamationCircleOutlined />,
  },
  {
    label: t('title.setting'),
    key: '/setting',
    icon: <SettingOutlined />,
    children: [
      {
        label: (
          <NavLink to="/setting/member">{t('title.setting_member')}</NavLink>
        ),
        key: '/setting/member',
      },
      {
        label: (
          <NavLink to="/setting/spotlight">
            {t('title.setting_spotlight')}
          </NavLink>
        ),
        key: '/setting/spotlight',
      },
      {
        label: (
          <NavLink to="/setting/superlike">
            {t('title.setting_superlike')}
          </NavLink>
        ),
        key: '/setting/superlike',
      },
    ],
  },
];
