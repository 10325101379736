import React from 'react';

import { IRouter } from 'src/interfaces/route';
import { PATH } from 'src/pages/report';
import { PATH as PATHDETAIL } from 'src/pages/reportDetail';
import { t } from 'i18next';
import Layout from 'src/components/layout';
import loadable from 'src/utils/Loadable';

const ReportRoute: IRouter[] = [
  {
    element: <Layout />,
    children: [
      {
        path: PATH,
        element: loadable(() => import('src/pages/report')),
        title: t('title.report_management'),
      },
      {
        path: PATHDETAIL,
        element: loadable(() => import('src/pages/reportDetail')),
      },
    ],
  },
];

export default ReportRoute;
