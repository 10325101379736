export enum MATCH_ACTION {
  ALL = '',
  LIKE = 1,
  SUPER_LIKE = 2,
  NOPE = 3,
  BACK = 4,
}

export enum MATCH_OPTION_TYPE {
  NAME = '1',
  EMAIL = '2',
}
