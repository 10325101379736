import React from 'react';
import styled from 'styled-components';
import { Badge, Button, Card, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { CaretLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import RowDetail from 'src/components/common/RowDetail';
import ImagePreview from 'src/components/common/ImagePreview';
import modalConfirm from 'src/utils/modalConfirm';
import UserStatus from 'src/components/badge/UserStatus';
import {
  formatDate,
  renderUserGender,
  renderUserShowMe,
} from 'src/utils/Helpers';
import { USER_STATUS } from 'src/enum/user';
import { updateStatus } from 'src/useCase/userDetail';
import { useAppDispatch } from 'src/store';
import RenderInterest from 'src/utils/RenderInterest';

type Props = {
  loading: boolean;
  entities: Response.UserDetailEntity | null;
};
const PersonalInformation: React.FC<Props> = ({ entities, loading }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let isInterest = !_.isEmpty(_.omitBy(entities?.interests, _.isEmpty));

  const handleConfirm = (status: number) => {
    modalConfirm({
      title: t('message.user_confirm'),
      okText: t('button.confirm'),
      style: { textAlign: 'center' },
      onOk: async () => {
        await dispatch(
          updateStatus({ id: String(entities?.id), payload: { status } }),
        );
      },
    });
  };

  return (
    <Wrapper>
      <Card loading={loading}>
        <RowDetail title={t('label.userId')} value={entities?.id || 'empty'} />
        <RowDetail title={t('label.name')} value={entities?.name || 'empty'} />
        <RowDetail
          title={t('label.email')}
          value={entities?.email || 'empty'}
        />
        <RowDetail
          title={t('label.birth')}
          value={entities?.birthday || 'empty'}
        />
        <RowDetail
          title={t('label.gender')}
          value={
            (entities?.gender &&
              renderUserGender(Number(entities?.gender) || 0)) ||
            'empty'
          }
        />
        <RowDetail
          title={t('label.sexual')}
          value={
            (!!entities?.sexual_orientation.length &&
              entities?.sexual_orientation.map((v, i) => v.name + ', ')) ||
            'empty'
          }
        />
        <RowDetail
          title={t('label.show_me')}
          value={
            (entities?.show_me.length &&
              entities?.show_me.map((v, i) =>
                renderUserShowMe(Number(v || 0)),
              )) ||
            'empty'
          }
        />
        {isInterest && (
          <RowDetail
            title={t('label.interest')}
            value={
              <RenderInterest
                creativities={entities?.interests.creativities || []}
                sports={entities?.interests.sports || []}
                pets={entities?.interests.pets || []}
                travelings={entities?.interests.travelings || []}
              />
            }
          />
        )}
        <RowDetail
          title={t('label.location')}
          value={entities?.address || 'empty'}
        />
        <RowDetail
          title={t('label.photo')}
          value={<ImagePreview list={entities?.photos || []} srcKey={'path'} />}
          layout={{ valueCol: { span: 20 } }}
        />
        <RowDetail
          title={t('label.signUp_date')}
          value={formatDate(entities?.created_at) || 'empty'}
        />
        <RowDetail
          title={t('label.activity_status')}
          value={<UserStatus status={Number(entities?.status || 0)} />}
          actions={
            <Space>
              <Button
                type="dashed"
                icon={<Badge status={'success'} />}
                onClick={() => handleConfirm(Number(USER_STATUS.NORMAL))}
                disabled={entities?.status === USER_STATUS.NORMAL}
              >
                {t('button.normal')}
              </Button>
              <Button
                type="dashed"
                icon={<Badge status={'error'} />}
                onClick={() => handleConfirm(Number(USER_STATUS.BLOCKED))}
                disabled={entities?.status === USER_STATUS.BLOCKED}
              >
                {t('button.block')}
              </Button>
            </Space>
          }
          layout={{
            valueCol: { span: 9 },
          }}
          style={{ alignItems: 'center' }}
        />
        <RowDetail
          layout={{
            valueCol: { span: 9 },
          }}
          title={t('label.wallet_address')}
          value={
            entities?.addresses
              ? entities?.addresses[0]?.deposit_address
              : 'empty'
          }
        />
      </Card>
      <Button icon={<CaretLeftOutlined />} onClick={() => navigate('/user')}>
        {t('button.back')}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ant-card {
    margin-bottom: 10px;
  }
  .ant-form-item-label {
    text-align: left;
  }
  .ant-radio-button-wrapper {
    margin-right: 8px;
  }
`;

export default PersonalInformation;
