import React from 'react';
import styled from 'styled-components';

import Search from './Search';
import Table from './Table';
import { useAppSelector } from 'src/store';

const Container: React.FC = () => {
  const props = useAppSelector((state) => state.domain.user.list);

  return (
    <React.Fragment>
      <Search />
      <MarginBottom />
      <Table {...props} />
    </React.Fragment>
  );
};

const MarginBottom = styled.div`
  margin-bottom: 20px;
`;

export default Container;
