// import React from 'react';

import {
  MEMBER_DURATION,
  MEMBER_DURATION_STATUS,
  SETTING_OPTION,
  CONFIG_OPTION,
  MEMBER_PACKAGE,
} from 'src/enum/memberPackage';

export * from './common';

export const member_package_duration: App.OptionsFnc = (t) => [
  {
    label: t('option.all'),
    value: MEMBER_DURATION.ALL,
  },
  {
    label: t('option.one_month'),
    value: MEMBER_DURATION.ONE_MONTH,
  },
  {
    label: t('option.three_months'),
    value: MEMBER_DURATION.THREE_MONTHS,
  },
  {
    label: t('option.six_months'),
    value: MEMBER_DURATION.SIX_MONTHS,
  },
];
export const member_package_duration_status: App.OptionsFnc = (t) => [
  {
    label: t('option.all'),
    value: MEMBER_DURATION_STATUS.ALL,
  },
  {
    label: t('option.watting'),
    value: MEMBER_DURATION_STATUS.WAITTING,
  },
  {
    label: t('option.success'),
    value: MEMBER_DURATION_STATUS.SUCCESS,
  },
  {
    label: t('option.failure'),
    value: MEMBER_DURATION_STATUS.FAILURE,
  },
  {
    label: t('option.canceled'),
    value: MEMBER_DURATION_STATUS.CANCELED,
  },
  {
    label: t('option.refunded'),
    value: MEMBER_DURATION_STATUS.REFUNDED,
  },
];

export const member_package: App.OptionsFnc = (t) => [
  {
    label: t('option.all'),
    value: MEMBER_PACKAGE.ALL,
  },
  {
    label: t('option.lofi_plus'),
    value: MEMBER_PACKAGE.PLUS,
  },
  {
    label: t('option.lofi_gold'),
    value: MEMBER_PACKAGE.GOLD,
  },
  {
    label: t('option.lofi_platinum'),
    value: MEMBER_PACKAGE.PLATINUM,
  },
  {
    label: t('option.lofi_vip'),
    value: MEMBER_PACKAGE.VIP,
  },
];
export const setting_option: App.OptionsFnc = (t) => [
  { label: t('option.usdt'), value: SETTING_OPTION.USDT },
  { label: t('option.bnb'), value: SETTING_OPTION.BNB },
  { label: t('option.credit_card'), value: SETTING_OPTION.CREDIT_CARD },
  { label: t('option.payment_in_app'), value: SETTING_OPTION.PAYMENT_IN_APP },
];
export const config_option: App.OptionsFnc = (t) => [
  { label: t('option.usdt'), value: CONFIG_OPTION.USDT },
  { label: t('option.bnb'), value: CONFIG_OPTION.BNB },
  { label: t('option.lolo'), value: CONFIG_OPTION.LOLO },
  { label: t('option.payment_in_app'), value: CONFIG_OPTION.PAYMENT_IN_APP },
];
