import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentWrapper } from 'src/components/common';
import MatchSwipeContainer from 'src/containers/MatchSwipe';
import { fetchResource } from 'src/useCase/matchSwipe';
import useQueryUrl from 'src/hook/useQueryUrl';
import { useAppDispatch } from 'src/store';

const Page: App.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { query } = useQueryUrl();

  useEffect(() => {
    dispatch(fetchResource(query));
  }, [query]);

  return (
    <ContentWrapper title={t('title.swipe_interaction')}>
      <MatchSwipeContainer />
    </ContentWrapper>
  );
};

export const PATH_SWIPE = '/match/swipe' as const;
export default Page;
