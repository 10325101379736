import React, { useEffect } from 'react';

import ConfigSpotlightContainer from 'src/containers/ConfigSpotlight';
import { ContentWrapper } from 'src/components/common';
import { fetchResource } from 'src/useCase/settingExtraPrice';
import { useAppDispatch } from 'src/store';
import { CONFIG_SETTING } from 'src/enum/settingMemberPackage';

const Page: App.FC<SearchParams.Users> = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchResource({ type: CONFIG_SETTING.SPOTLIGHT }));
  }, []);

  return (
    <ContentWrapper title="">
      <ConfigSpotlightContainer />
    </ContentWrapper>
  );
};

export const PATHSPOTLINGT = '/setting/spotlight' as const;
export default Page;
