import React from 'react';

import Layout from 'src/components/layout';
import loadable from 'src/utils/Loadable';
import { IRouter } from 'src/interfaces/route';
import { PATH } from 'src/pages/settingMember';
import { PATHSPOTLINGT } from 'src/pages/configSpotlight';
import { PATHSUPERLIKE } from 'src/pages/configSuperlike';
import { t } from 'src/libs/i18n';

const SettingRoute: IRouter[] = [
  {
    element: <Layout />,
    children: [
      {
        path: PATH,
        element: loadable(() => import('src/pages/settingMember')),
        title: t('title.user_management'),
      },
      {
        path: PATHSPOTLINGT,
        element: loadable(() => import('src/pages/configSpotlight')),
        title: t('title.user_management'),
      },
      {
        path: PATHSUPERLIKE,
        element: loadable(() => import('src/pages/configSuperlike')),
        title: t('title.user_management'),
      },
    ],
  },
];

export default SettingRoute;
