import React, { useMemo } from 'react';
import { I18n } from 'src/libs/i18n';
import { ColumnType } from 'antd/es/table';
import { RadioChangeEvent } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';

import customDayjs from 'src/libs/day';
import { SortIcon } from 'src/components/common';
import { DATE_TIME_FORMAT, DATE_TIME_HOUR } from '../constants/app';
import { MATCH_ACTION } from '../enum/match';
import {
  REPORT_REASON,
  REPORT_FAKE_PROFILE_OR_SPAM,
  REPORT_IMAPPROPRIATE_MESSAGE,
  REPORT_IMAPPROPRIATE_PROFILE_PHOTO,
  REPORT_OFFLINE_BEHAVIOR,
} from 'src/enum/report';
import { USER_GENDER, USER_SHOW_ME } from 'src/enum/user';
import { AMOUNT_TYPE, MEMBER_PACKAGE } from 'src/enum/memberPackage';
import {
  PAYMENT_METHODS,
  PAYMENT_TYPE,
  SETTING_DURATION,
  TITLE_MP,
} from 'src/enum/settingMemberPackage';

export type GetSortProps<T> = {
  sortInfo?: App.SortInfo<T>;
  field: keyof T;
  title: string;
};

export const getSortProps = <T>({
  sortInfo,
  field,
  title,
}: GetSortProps<T>) => {
  if (!sortInfo) return {};

  return {
    sortOrder: field === sortInfo.sort_by ? sortInfo.order_by : null,
    sorter: true,
    dataIndex: field,
    showSorterTooltip: {
      title: I18n.t('message.click_to_sort'),
      placement: 'topRight',
    },
    title({ sortColumns }) {
      const sortColumn = sortColumns?.find((col) => Boolean(col.order));

      return React.createElement(SortIcon, {
        column: sortColumn?.column,
        order: sortColumn?.order,
        title,
        field: String(field),
      });
    },
  } as ColumnType<T>;
};

export const formatDate = (
  day?: string | null,
  options?: { format?: string; defaultValue?: string },
) => {
  const { defaultValue = '-', format = DATE_TIME_HOUR } = options || {};

  if (!day) return defaultValue;

  if (customDayjs(day).isValid()) {
    return customDayjs(day).format(format);
  }

  return defaultValue;
};

export const onChangeQuickPeriod = (
  e: RadioChangeEvent,
  setValue: Function,
) => {
  const value = e.target.value;
  setValue('quick_period_search', value);
  const setFromDate = setValue.bind(null, 'from_date');
  const setToDate = setValue.bind(null, 'to_date');
  setValue('to_date', dayjs().format(DATE_TIME_FORMAT));

  switch (value) {
    case 'today':
      setFromDate(dayjs().format(DATE_TIME_FORMAT));
      setToDate(dayjs().format(DATE_TIME_FORMAT));
      break;
    case 'yesterday':
      setFromDate(dayjs().subtract(1, 'day').format(DATE_TIME_FORMAT));
      setToDate(dayjs().format(DATE_TIME_FORMAT));
      break;
    case 'week':
      setFromDate(dayjs().subtract(1, 'week').format(DATE_TIME_FORMAT));
      setToDate(dayjs().format(DATE_TIME_FORMAT));

      break;
    case 'month':
      setFromDate(dayjs().subtract(1, 'month').format(DATE_TIME_FORMAT));
      setToDate(dayjs().format(DATE_TIME_FORMAT));

      break;
    default:
      break;
  }
};

export const checkActionMatch = (action: number) => {
  switch (action) {
    case MATCH_ACTION.LIKE:
      return I18n.t('label.like');
    case MATCH_ACTION.SUPER_LIKE:
      return I18n.t('label.super_like');
    case MATCH_ACTION.BACK:
      return I18n.t('label.back');
    case MATCH_ACTION.NOPE:
      return I18n.t('label.nope');
    default:
      return;
  }
};

export const checkSubReason = (reason, subReason) => {
  let isOther = false;

  switch (reason) {
    case REPORT_REASON.FAKE_PROFILE_OR_SPAM:
      if (subReason === REPORT_FAKE_PROFILE_OR_SPAM.OTHER) {
        isOther = true;
      }
      return isOther;
    case REPORT_REASON.IMAPPROPRIATE_MESSAGE:
      if (subReason === REPORT_IMAPPROPRIATE_MESSAGE.OTHER) {
        isOther = true;
      }
      return isOther;
    case REPORT_REASON.IMAPPROPRIATE_PROFILE_PHOTO:
      if (subReason === REPORT_IMAPPROPRIATE_PROFILE_PHOTO.OTHER) {
        isOther = true;
      }
      return isOther;
    case REPORT_REASON.OFFLINE_BEHAVIOR:
      if (subReason === REPORT_OFFLINE_BEHAVIOR.OTHER) {
        isOther = true;
      }
      return isOther;
    default:
      return isOther;
  }
};
export const renderUserGender = (gender: USER_GENDER) => {
  switch (gender) {
    case USER_GENDER.MAN:
      return I18n.t('option.man');
    case USER_GENDER.WOMAN:
      return I18n.t('option.woman');
    case USER_GENDER.OTHER:
      return I18n.t('option.other');
    default:
      return '-';
  }
};

export const renderUserShowMe = (option: USER_SHOW_ME) => {
  switch (option) {
    case USER_SHOW_ME.WOMAN:
      return I18n.t('option.woman') + ', ';
    case USER_SHOW_ME.MAN:
      return I18n.t('option.man') + ', ';
    case USER_SHOW_ME.EVERYONE:
      return I18n.t('option.everyone');
    default:
      return '-';
  }
};

export const renderSupReason = (reason, supReason) => {
  switch (reason) {
    case REPORT_REASON.FAKE_PROFILE_OR_SPAM:
      switch (supReason) {
        case REPORT_FAKE_PROFILE_OR_SPAM.THIS_USER_SENT_ME_A_SUSPICIOUS_LINK:
          return I18n.t('option.This_user_sent_me_a_suspicious_link');
        case REPORT_FAKE_PROFILE_OR_SPAM.THIS_USER_ASKED_ME_FOR_MONEY:
          return I18n.t('option.This_user_asked_me_for_money');
        case REPORT_FAKE_PROFILE_OR_SPAM.BELIEVE_THE_PROFILE_IS_FAKE:
          return I18n.t(
            'option.This_is_a_public_figure_-_and_I_believe_the_profile_is_fake',
          );
        case REPORT_FAKE_PROFILE_OR_SPAM.NOT_INTERESTED_IN_THIS_PERSON:
          return I18n.t('option.Not_interested_in_this_person');
        case REPORT_FAKE_PROFILE_OR_SPAM.OTHER:
          return I18n.t('option.Other');
        default:
          return I18n.t('empty');
      }
    case REPORT_REASON.IMAPPROPRIATE_MESSAGE:
      switch (supReason) {
        case REPORT_IMAPPROPRIATE_MESSAGE.BEING_RUDE_HARASSING_ME:
          return I18n.t('option.This_user_is_being_rude/harassing_me');
        case REPORT_IMAPPROPRIATE_MESSAGE.SENT_ME_SEXUAL_MESSAGES:
          return I18n.t('option.This_user_sent_me_sexual_messages');
        case REPORT_IMAPPROPRIATE_MESSAGE.MESSAGE_CONTAINS_HATE_SPEECH:
          return I18n.t('option.This_message_contains_hate_speech');
        case REPORT_IMAPPROPRIATE_MESSAGE.OTHER:
          return I18n.t('option.Other');
        default:
          return I18n.t('empty');
      }
    case REPORT_REASON.IMAPPROPRIATE_PROFILE_PHOTO:
      switch (supReason) {
        case REPORT_IMAPPROPRIATE_PROFILE_PHOTO.THIS_PHOTO_CONTAINS_NUDITY:
          return I18n.t('option.This_photo_contains_nudity');
        case REPORT_IMAPPROPRIATE_PROFILE_PHOTO.THIS_PHOTO_IS_VIOLENT:
          return I18n.t('option.This_photo_is_violent');
        case REPORT_IMAPPROPRIATE_PROFILE_PHOTO.DO_NOT_LIKE_THIS_PHOTO:
          return I18n.t('option.I_do_not_like_this_photo');
        case REPORT_IMAPPROPRIATE_PROFILE_PHOTO.THIS_IS_A_FAKE_PROFILE:
          return I18n.t('option.This_is_a_fake_profile');
        case REPORT_IMAPPROPRIATE_PROFILE_PHOTO.OTHER:
          return I18n.t('option.Other');
        default:
          return I18n.t('empty');
      }
    case REPORT_REASON.OFFLINE_BEHAVIOR:
      switch (supReason) {
        case REPORT_OFFLINE_BEHAVIOR.LIKE_TO_REPORT_THEM:
          return I18n.t(
            'option.I_met_this_person_and_I_would_like_to_report_them',
          );
        case REPORT_OFFLINE_BEHAVIOR.I_KNOW_THIS_PERSON:
          return I18n.t(
            'option.I_know_this_person_and_I_would_like_to_report_them',
          );
        case REPORT_OFFLINE_BEHAVIOR.OTHER:
          return I18n.t('option.Other');

        default:
          return I18n.t('empty');
      }
    default:
      return I18n.t('empty');
  }
};

export const renderReportReason = (reason) => {
  switch (reason) {
    case REPORT_REASON.FAKE_PROFILE_OR_SPAM:
      return I18n.t('option.fake_profile_or_spam');
    case REPORT_REASON.IMAPPROPRIATE_MESSAGE:
      return I18n.t('option.imappropriate_message');
    case REPORT_REASON.IMAPPROPRIATE_PROFILE_PHOTO:
      return I18n.t('option.imappropriate_profile_photo');
    case REPORT_REASON.OFFLINE_BEHAVIOR:
      return I18n.t('option.offline_behavior');
    default:
      return I18n.t('empty');
  }
};

export const renderMemberPackage = (memberPackage) => {
  switch (memberPackage) {
    case MEMBER_PACKAGE.ALL:
      return I18n.t('option.all');
    case MEMBER_PACKAGE.PLUS:
      return I18n.t('option.lofi_plus');
    case MEMBER_PACKAGE.GOLD:
      return I18n.t('option.lofi_gold');
    case MEMBER_PACKAGE.PLATINUM:
      return I18n.t('option.lofi_platinum');
    case MEMBER_PACKAGE.VIP:
      return I18n.t('option.lofi_vip');
  }
};

export function getTotalByAmountType(memberPackages) {
  const result = useMemo(() => {
    let totalUSDT = 0;
    let totalBUSD = 0;
    let totalBNB = 0;
    let totalUSD = 0;
    let totalLOLO = 0;

    memberPackages.map((v, i) => {
      switch (v.amount_type) {
        case AMOUNT_TYPE.USDT:
          totalUSDT += Number(v.payment_amount);
          break;
        case AMOUNT_TYPE.BUSD:
          totalBUSD += Number(v.payment_amount);
          break;
        case AMOUNT_TYPE.BNB:
          totalBNB = totalBNB + Number(v.payment_amount);
          break;
        case AMOUNT_TYPE.USD:
          totalUSD += Number(v.payment_amount);
          break;
        case AMOUNT_TYPE.LOLO:
          totalLOLO += Number(v.payment_amount);
          break;
      }
    });
    return (
      totalUSDT +
      ' USDT | ' +
      totalBUSD +
      ' BUSD | ' +
      totalBNB +
      ' BNB | ' +
      totalUSD +
      ' USD | ' +
      totalLOLO +
      ' LOLO | '
    );
  }, [memberPackages]);
  return result;
}

export const getPrices = (
  listValues: Array<Response.SettingExtraPrice[]>,
  packageType: TITLE_MP,
  duration: SETTING_DURATION,
): string | undefined => {
  const amount = listValues[packageType]?.[duration]?.amount;
  return amount;
};

export const getID = (listValues, packageType, duration) => {
  const id = listValues[packageType]?.[duration].id;
  return id;
};

type GetOptionConfigSetting = {
  value: Response.SettingEntity[] | null;
  payment: PAYMENT_METHODS;
  type: PAYMENT_TYPE;
};

export const getOptionConfigSetting = (v: GetOptionConfigSetting) => {
  const { value, payment, type } = v;

  return _.map(value, (config) => {
    return config.prices[payment]?.[type];
  });
};
