import React from 'react';
// import styled from 'styled-components';

import { checkActionMatch, formatDate } from 'src/utils/Helpers';
// import imgfake from 'src/assets/logo.png';

const column: App.ColumnsFnc<Response.MatchSwipeEntity> = (t, sortInfo) => [
  {
    title: t('column.id'),
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: t('column.name'),
    key: 'name',
    render: (v, row) => {
      return (
        <div>
          {/* <AvtImg
            src={row.avatar_url ? row.avatar_url : imgfake}
            onError={(e) => {
              e.currentTarget.src = imgfake;
            }}
          /> */}
          <span>{row.user_name || '-'}</span>
        </div>
      );
    },
  },
  {
    title: t('column.email'),
    dataIndex: 'user_email',
    key: 'email',
  },
  {
    title: t('column.action'),
    render: (v, row) => {
      return checkActionMatch(row.action);
    },
  },
  {
    title: t('column.target_user_name'),
    dataIndex: 'target_user_name',
    key: 'target_name',
  },
  {
    title: t('column.target_user_email'),
    dataIndex: 'target_user_email',
    key: 'target_email',
  },
  {
    title: t('column.action_date'),
    key: 'action_date',
    render: (v, row) => {
      return formatDate(row.created_at);
    },
  },
];
// const AvtImg = styled.img`
//   vertical-align: middle;
//   width: 20px;
//   height: 20px;
//   margin-right: 5px;
//   border-radius: 50%;
// `;
export default column;
