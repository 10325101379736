import HTTPRequest from '../index';

const apiPath = '/admin/users';

export type UpdateStatus = {
  status: number;
};

class User extends HTTPRequest {
  getList(params: Payload.Users) {
    return this.get<Response.UserListResponse>(apiPath, { params });
  }

  getDetail(id?: string) {
    return this.get<Response.UserDetailResponse>(`${apiPath}/${id}`);
  }

  updateStatus(id?: string, data?: UpdateStatus) {
    return this.put(`${apiPath}/${id}`, data);
  }
}

export default new User();
