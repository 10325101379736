import { createAsyncThunk } from '@reduxjs/toolkit';
import settingExtraPriceAction from '../store/domain/setting/extraPrice';
import setting, { UpdateExtraPrice } from '../api/requests/setting';
import { Config } from '../api/requests/setting';
import { CONFIG_SETTING } from '../enum/settingMemberPackage';

export const fetchResource = createAsyncThunk<void, Config>(
  'get_extra_price',
  async ({ type }, { dispatch }) => {
    try {
      dispatch(settingExtraPriceAction.updateLoading(true));

      const res = await setting.getConfigExtraPrice({ type });

      dispatch(settingExtraPriceAction.updateEntities(res.data.data));

      dispatch(settingExtraPriceAction.updateLoading(false));
    } catch (err) {
      dispatch(settingExtraPriceAction.updateLoading(false));
    }
  },
);

export const updateSettingExtraPrices = createAsyncThunk<any, UpdateExtraPrice>(
  'update_extra_price',
  async (
    { prices, type = CONFIG_SETTING.SPOTLIGHT },
    { dispatch, fulfillWithValue, rejectWithValue },
  ) => {
    try {
      await setting.updateExtraPrice({ prices });

      await dispatch(fetchResource({ type }));

      return fulfillWithValue({ type: 'success', message: 'Success' });
    } catch (e) {
      return rejectWithValue({ type: 'error', message: 'Error' });
    }
  },
);
