import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Card } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import useFormSetting, { FormValues } from './useForm';
import USDT from '../PaymentMethods/USDT';
import BNB from '../PaymentMethods/BNB';
import CreditCard from '../PaymentMethods/CreditCard';
import { RadioGroupField } from 'src/components/form';
import { t } from 'src/libs/i18n';
import { setting_option } from 'src/constants/optionsSelect/memberPackage';
import { SETTING_OPTION } from 'src/enum/settingMemberPackage';
import LoadingPage from 'src/components/application/LoadingPage';
import PaymentInApp from '../PaymentMethods/PaymentInApp';

type Props = {
  loading: boolean;
  entities: Response.SettingEntity[] | null;
};

const Main: React.FC<Props> = ({ entities, loading }) => {
  const { form, creditPrice, USDTPrice, BNBPrice, IAPPrice } =
    useFormSetting(entities);

  const { control, watch } = form;

  const getSettingOption = (option: string) => {
    switch (option) {
      case SETTING_OPTION.USDT:
        return <USDT price={USDTPrice} />;
      case SETTING_OPTION.BNB:
        return <BNB price={BNBPrice} />;
      case SETTING_OPTION.CREDIT_CARD:
        return <CreditCard price={creditPrice} />;
      case SETTING_OPTION.PAYMENT_IN_APP:
        return <PaymentInApp price={IAPPrice} />;
      default:
        return;
    }
  };

  const cardTitle = (
    <React.Fragment>
      <EditOutlined />
      <span style={{ marginLeft: '8px' }}>{t('title.setting_member')}</span>
    </React.Fragment>
  );

  return (
    <Card title={cardTitle}>
      {loading ? (
        <LoadingPage />
      ) : (
        <Fragment>
          <Content>
            <Title fontSize="24px">{t('label.payment_method')}</Title>
            <RadioGroupField<FormValues>
              controller={{ name: 'options', control }}
              radioGroupProps={{
                options: setting_option(t),
              }}
            />
          </Content>
          <Content>{getSettingOption(watch().options)}</Content>
        </Fragment>
      )}
    </Card>
  );
};

const Title = styled.h3`
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 20px;
`;

const Content = styled.div`
  .input-field {
    margin-right: 54px;
  }
`;

export default Main;
