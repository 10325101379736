import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { TabsWrapper } from 'src/components/common';
import UserDetailContainer from 'src/containers/UserDetail';
import { useAppDispatch } from 'src/store';
import { fetchResource } from 'src/useCase/userDetail';

const Page: App.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchResource(id));
  }, []);

  return (
    <TabsWrapper>
      <UserDetailContainer />
    </TabsWrapper>
  );
};

export const PATH = '/user/:id' as const;
export default Page;
