import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

import reportAction from 'src/store/domain/report/list';
import { REPORT_SEARCH_TYPE, REPORT_OPTION_TYPE } from 'src/enum/report';
import appAction from 'src/store/ui/app';
import reportRequest from 'src/api/requests/report';

interface FetchResource extends SearchParams.Report {}

const getParams = (args: FetchResource) => {
  const {
    limit,
    page,
    search_type,
    period_search,
    search_text,
    reason,
    from_date,
    to_date,
  } = args;

  const params: Payload.Report = {
    limit,
    page,
    keyword: search_text,
    period_search,
    reason,
    type:
      (search_type === REPORT_SEARCH_TYPE.REPORT_NAME &&
        REPORT_OPTION_TYPE.REPORT_NAME) ||
      REPORT_OPTION_TYPE.EMAIL,
    created_at_from: from_date,
    created_at_to: to_date,
  };

  return params;
};

export const fetchResource = createAsyncThunk<void, FetchResource>(
  'get_report',
  async (args, { dispatch }) => {
    try {
      dispatch(reportAction.updateLoading(true));
      const params = getParams(args);
      const res = await reportRequest.getList(_.omitBy(params, _.isEmpty));
      const { data, meta } = res.data;

      if (data) {
        dispatch(reportAction.updateEntities(data));
      }
      if (meta) {
        dispatch(
          appAction.updatePagination({
            total: meta.total,
            current: meta.current_page,
          }),
        );
      }
      dispatch(reportAction.updateLoading(false));
    } catch (err) {
      dispatch(reportAction.updateLoading(false));
    }
  },
);
