import { createAsyncThunk } from '@reduxjs/toolkit';
import { TFunction } from 'i18next';

import reportRequest, { ValueResolve } from 'src/api/requests/report';
import reportDetailAction from 'src/store/domain/reportDetail/detail';

export interface PostReportResolve {
  valueResolve: ValueResolve;
  t: TFunction;
}

export const fetchResource = createAsyncThunk<void, string | undefined>(
  'get_report_detail',
  async (id, { dispatch }) => {
    try {
      dispatch(reportDetailAction.updateLoading(true));
      const res = await reportRequest.getDetail(String(id));
      dispatch(reportDetailAction.updateEntities(res.data.data[0]));
      dispatch(reportDetailAction.updateLoading(false));
    } catch (err) {
      dispatch(reportDetailAction.updateLoading(false));
    }
  },
);

export const updateSolution = createAsyncThunk<
  any,
  { id: string; payload: ValueResolve }
>(
  'edit_text',
  async ({ id, payload }, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      await reportRequest.updateSolution(id, payload);

      await dispatch(fetchResource(id));

      return fulfillWithValue({ type: 'success', message: 'Success' });
    } catch (err) {
      return rejectWithValue({ type: 'error', message: 'Error' });
    }
  },
);
