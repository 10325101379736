import { createAsyncThunk } from '@reduxjs/toolkit';

import userDetailAction from 'src/store/domain/user/detail';
import userRequest, { UpdateStatus } from 'src/api/requests/user';

export const fetchResource = createAsyncThunk<void, string | undefined>(
  'get_user_detail',
  async (id, { dispatch }) => {
    try {
      dispatch(userDetailAction.updateLoading(true));
      const res = await userRequest.getDetail(id);
      dispatch(userDetailAction.updateEntities(res.data.data));
      dispatch(userDetailAction.updateLoading(false));
    } catch (err) {
      dispatch(userDetailAction.updateLoading(false));
    }
  },
);

export const updateStatus = createAsyncThunk<
  any,
  { id?: string; payload: UpdateStatus }
>(
  'edit_notice',
  async ({ id, payload }, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      await userRequest.updateStatus(id, payload);

      await dispatch(fetchResource(id));

      return fulfillWithValue({ type: 'success', message: 'Success' });
    } catch (err) {
      return rejectWithValue({ type: 'error', message: 'Error' });
    }
  },
);
