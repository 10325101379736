import React from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import CustomTabs from 'src/components/custom/CustomTabs';
import { USER_DETAIL } from 'src/enum/user';
import PersonalInformation from './PersonalInformation';
import Interaction from './Interaction';
import { useAppSelector } from 'src/store';

const UserDetail: React.FC = () => {
  const { t } = useTranslation();
  const props = useAppSelector((state) => state.domain.user.detail);

  return (
    <CustomTabs tabKey="user">
      <Tabs.TabPane
        tab={t('tab.personal_information')}
        key={USER_DETAIL.PERSONAL_INFORMATION}
      >
        <PersonalInformation {...props} />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('tab.interaction')} key={USER_DETAIL.INTERACTION}>
        <Interaction {...props} />
      </Tabs.TabPane>
    </CustomTabs>
  );
};

export default UserDetail;
