import { combineReducers } from '@reduxjs/toolkit';

import user from './user';
import match from './match';
import memberPackage from './memberPackage';
import report from './report';
import reportDetail from './reportDetail';
import settingMember from './settingMemberPackage';
import setting from './setting';

export default combineReducers({
  user,
  match,
  memberPackage,
  report,
  reportDetail,
  settingMember,
  setting,
});
