import HTTPRequest from '../index';

const apiPathMP = 'admin/settings/package-price';
const apiPath = '/admin/settings/config-extra-price';

export type Config = {
  type: string;
};

export type UpdateExtraPrice = {
  prices: Array<{ id?: number; amount?: string }>;
} & Partial<Config>;
export type updateMemberPackagePrices = {
  prices: Array<{ id?: number; amount?: string }>;
};

class Setting extends HTTPRequest {
  getMemberPackagePrices() {
    return this.get<Response.SettingResponse>(apiPathMP);
  }
  updateMemberPackagePrices(data: updateMemberPackagePrices) {
    return this.put(apiPathMP, { ...data });
  }

  getConfigExtraPrice(params: Config) {
    return this.get<Response.SettingExtraPriceResponse>(apiPath, { params });
  }

  updateExtraPrice(data: Omit<UpdateExtraPrice, 'type'>) {
    return this.put(apiPath, { ...data });
  }
}
export default new Setting();
