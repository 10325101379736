import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

import userAction from 'src/store/domain/user/list';
import appAction from 'src/store/ui/app';
import { USER_PERIOD_SEARCH, USER_SEARCH_TYPE } from '../enum/user';
import userRequest from 'src/api/requests/user';

interface FetchResource extends SearchParams.Users {}

const getParams = (args: FetchResource) => {
  const {
    limit,
    page,
    search_type,
    search_text,
    status,
    period_search,
    gender,
    from_date,
    to_date,
    member,
  } = args;

  const params: Payload.Users = {
    [search_type as USER_SEARCH_TYPE]: search_text,
    limit,
    page,
    status,
    gender,
    package_id: member,
  };

  switch (period_search) {
    case USER_PERIOD_SEARCH.SIGNUP_DATE:
      params.created_at_from = from_date;
      params.created_at_to = to_date;
      break;
    case USER_PERIOD_SEARCH.LAST_VISIT:
      params.last_active_from = from_date;
      params.last_active_to = to_date;
      break;
  }

  return params;
};

export const fetchResource = createAsyncThunk<void, FetchResource>(
  'get_users',
  async (args, { dispatch }) => {
    try {
      dispatch(userAction.updateLoading(true));
      const params = getParams(args);
      const res = await userRequest.getList(_.omitBy(params, _.isEmpty));
      const { data, meta } = res.data;

      if (data) {
        dispatch(userAction.updateEntities(data));
      }

      if (meta) {
        dispatch(
          appAction.updatePagination({
            total: meta.total,
            current: meta.current_page,
          }),
        );
      }

      dispatch(userAction.updateLoading(false));
    } catch (err) {
      dispatch(userAction.updateLoading(false));
    }
  },
);
