import React from 'react';

import UserStatus from 'src/components/badge/UserStatus';
import {
  USER_STATUS,
  USER_MEMBER,
  USER_PERIOD_SEARCH,
  USER_GENDER,
  USER_SHOW_ME,
} from 'src/enum/user';

export * from './common';

export const user_status: App.OptionsFnc = (t) => [
  {
    label: <UserStatus status={USER_STATUS.ALL} />,
    value: USER_STATUS.ALL,
  },
  {
    label: <UserStatus status={USER_STATUS.NORMAL} />,
    value: USER_STATUS.NORMAL,
  },
  {
    label: <UserStatus status={USER_STATUS.BLOCKED} />,
    value: USER_STATUS.BLOCKED,
  },
];

export const user_member_package: App.OptionsFnc = (t) => [
  {
    label: t('option.all'),
    value: USER_MEMBER.ALL,
  },
  {
    label: t('option.lofi_normal'),
    value: USER_MEMBER.NORMAL,
  },
  {
    label: t('option.lofi_plus'),
    value: USER_MEMBER.PLUS,
  },
  {
    label: t('option.lofi_gold'),
    value: USER_MEMBER.GOLD,
  },
  {
    label: t('option.lofi_platinum'),
    value: USER_MEMBER.PLATINUM,
  },
  {
    label: t('option.lofi_vip'),
    value: USER_MEMBER.VIP,
  },
];

export const user_period_search: App.OptionsFnc = (t) => [
  { label: t('option.signup_date'), value: USER_PERIOD_SEARCH.SIGNUP_DATE },
  { label: t('option.last_visit'), value: USER_PERIOD_SEARCH.LAST_VISIT },
];

export const user_gender: App.OptionsFnc = (t) => [
  { label: t('option.woman'), value: USER_GENDER.WOMAN },
  { label: t('option.man'), value: USER_GENDER.MAN },
  { label: t('option.other'), value: USER_GENDER.OTHER },
];

export const user_show_me: App.OptionsFnc = (t) => [
  { label: t('option.woman'), value: USER_SHOW_ME.WOMAN },
  { label: t('option.man'), value: USER_SHOW_ME.MAN },
  { label: t('option.other'), value: USER_SHOW_ME.EVERYONE },
];
