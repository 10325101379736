import React from 'react';
import styled from 'styled-components';

import { useAppSelector } from 'src/store';
import ReportDetail from './Main/index';

const ReportDetailContainer: React.FC = () => {
  const props = useAppSelector((state) => state.domain.reportDetail.detail);

  return (
    <React.Fragment>
      <Title>Report Detail</Title>
      <ReportDetail {...props} />
    </React.Fragment>
  );
};
const Title = styled.h3`
  font-style: normal;
  font-weight: 550;
  font-size: 18px;
  line-height: 20px;
  margin-top: 10px;
`;
export default ReportDetailContainer;
