import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentWrapper } from 'src/components/common';
import UserContainer from 'src/containers/User';
import { fetchResource } from 'src/useCase/user';
import useQueryUrl from 'src/hook/useQueryUrl';
import { useAppDispatch } from 'src/store';

const Page: App.FC<SearchParams.Users> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { query } = useQueryUrl<SearchParams.Users>();

  useEffect(() => {
    dispatch(fetchResource(query));
  }, [query]);

  return (
    <ContentWrapper title={t('title.user_management')}>
      <UserContainer />
    </ContentWrapper>
  );
};

export const PATH = '/user' as const;
export default Page;
