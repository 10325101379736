import { useForm } from 'react-hook-form';

import { SETTING_DURATION, TITLE_MP } from 'src/enum/settingMemberPackage';
import { getPrices } from 'src/utils/Helpers';

export type FormValues = {
  plus_aWeek?: string;
  plus_oneMonth?: string;
  plus_threeMonth?: string;
  plus_sixMonth?: string;

  gold_aWeek?: string;
  gold_oneMonth?: string;
  gold_threeMonth?: string;
  gold_sixMonth?: string;

  platinum_aWeek?: string;
  platinum_oneMonth?: string;
  platinum_threeMonth?: string;
  platinum_sixMonth?: string;

  vip_aWeek?: string;
  vip_oneMonth?: string;
  vip_threeMonth?: string;
  vip_sixMonth?: string;
};

type ValueUSDT = Array<Response.SettingExtraPrice[]>;

const useFormSetting = (price: ValueUSDT) => {
  const defaultValues: FormValues = {
    plus_aWeek: getPrices(price, TITLE_MP.plus, SETTING_DURATION.A_WEEK),
    plus_oneMonth: getPrices(price, TITLE_MP.plus, SETTING_DURATION.ONE_MONTH),
    plus_threeMonth: getPrices(
      price,
      TITLE_MP.plus,
      SETTING_DURATION.THREE_MONTHS,
    ),
    plus_sixMonth: getPrices(price, TITLE_MP.plus, SETTING_DURATION.SIX_MONTHS),
    gold_aWeek: getPrices(price, TITLE_MP.gold, SETTING_DURATION.A_WEEK),
    gold_oneMonth: getPrices(price, TITLE_MP.gold, SETTING_DURATION.ONE_MONTH),
    gold_threeMonth: getPrices(
      price,
      TITLE_MP.gold,
      SETTING_DURATION.THREE_MONTHS,
    ),
    gold_sixMonth: getPrices(price, TITLE_MP.gold, SETTING_DURATION.SIX_MONTHS),
    platinum_aWeek: getPrices(
      price,
      TITLE_MP.platinum,
      SETTING_DURATION.A_WEEK,
    ),
    platinum_oneMonth: getPrices(
      price,
      TITLE_MP.platinum,
      SETTING_DURATION.ONE_MONTH,
    ),
    platinum_threeMonth: getPrices(
      price,
      TITLE_MP.platinum,
      SETTING_DURATION.THREE_MONTHS,
    ),
    platinum_sixMonth: getPrices(
      price,
      TITLE_MP.platinum,
      SETTING_DURATION.SIX_MONTHS,
    ),
    vip_aWeek: getPrices(price, TITLE_MP.vip, SETTING_DURATION.A_WEEK),
    vip_oneMonth: getPrices(price, TITLE_MP.vip, SETTING_DURATION.ONE_MONTH),
    vip_threeMonth: getPrices(
      price,
      TITLE_MP.vip,
      SETTING_DURATION.THREE_MONTHS,
    ),
    vip_sixMonth: getPrices(price, TITLE_MP.vip, SETTING_DURATION.SIX_MONTHS),
  };

  const form = useForm<FormValues>({
    defaultValues: defaultValues,
    mode: 'all',
  });

  return {
    form,
  };
};

export default useFormSetting;
